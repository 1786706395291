// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, buildQueryParamsSearch } from '../../helpers/api';

import { GET_AUDIT, SEARCH_AUDIT, SEARCH_SESSION, SEARCH_LOGS } from './constants';

import { getAuditSuccess, getAuditFailed, searchAuditSuccess, searchAuditFailed } from './actions';
import { searchSessionSuccess, searchSessionFailed } from './actions-sessions';
import { searchLogsSuccess, searchLogsFailed } from './actions-logs';

import moment from 'moment';

function* fetchAudit() {
  try {
    const response = yield call(fetchJSON, '/audit');
    yield put(getAuditSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getAuditFailed(message));
  }
}

function* searchAudit(actionCreator) {
  try {
    const { params } = actionCreator;
    const response = yield call(fetchJSON, `/audit${buildQueryParamsSearch(params)}`);
    yield put(searchAuditSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(searchAuditFailed(message));
  }
}

function* searchSession(actionCreator) {
  try {
    const { params } = actionCreator;
    const response = yield call(fetchJSON, `/nodes/${params.nodeId}/sessions/${params.sessionId}`);
    yield put(searchSessionSuccess(response));
  } catch (error) {
    if (error.statusCode === 404) {
      yield put(searchSessionSuccess(null));
      return;
    }

    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(searchSessionFailed(message));
  }
}

function* searchLogs(actionCreator) {
  try {
    const { params } = actionCreator;
    params.dateFrom = formatDateToStr(params.dateFrom);
    params.dateTo = formatDateToStr(params.dateTo);

    const response = yield call(fetchJSON, `/logs/search${buildQueryParamsSearch(params)}`);
    yield put(searchLogsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(searchLogsFailed(message));
  }
}

export function* watchfetchAudit(): any {
  yield takeEvery(GET_AUDIT, fetchAudit);
}

export function* watchSearchAudit(): any {
  yield takeEvery(SEARCH_AUDIT, searchAudit);
}

export function* watchSearchSession(): any {
  yield takeEvery(SEARCH_SESSION, searchSession);
}

export function* watchSearchLogs(): any {
  yield takeEvery(SEARCH_LOGS, searchLogs);
}

function* operationsSaga(): any {
  yield all([fork(watchfetchAudit), 
    fork(watchSearchAudit), 
    fork(watchSearchSession),
    fork(watchSearchLogs)]);
}

function formatDateToStr(date) {
  if (date && date instanceof Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  return date;
}

export default operationsSaga;
