// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { GET_VERSIONS, DEPLOY_VERSION, GET_APPLIED_VERSIONS } from './constants';

import {
  getVersions,
  getVersionsSuccess,
  getVersionsFailed,
  deployVersionSuccess,
  deployVersionFailed,
  getAppliedVersionsSuccess,
  getAppliedVersionsFailed
} from './actions';

import { getCluster, getDashboard } from '../actions';

/**
 * fetch the versions data
 */
function* fetchVersions() {
  try {
    const response = yield call(fetchJSON, '/versions');
    yield put(getVersionsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getVersionsFailed(message));
  }
}

/**
 * Deploy Version in specific node
 * @param {*} payload - version and node id's
 */
function* putVersion({ payload: { version, node } }) {
  const options = {
    method: 'PUT',
  };
  try {
    yield call(fetchJSON, `/versions/${version}/nodes/${node}/apply`, options);
    yield all([put(deployVersionSuccess()), put(getVersions()), put(getCluster()), yield put(getDashboard())]);
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
      case 422:
        message = 'Internal Server Error';
        break;
      case 403:
        message = 'Invalid credentials';
        break;
      default:
        message = 'An unexpected error occurred, please try again';
    }
    yield put(deployVersionFailed(message));
  }
}

/**
 * fetch the versions data
 */
function* fetchAppliedVersions() {
  try {
    const response = yield call(fetchJSON, '/nodes/applied-version');
    yield put(getAppliedVersionsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getAppliedVersionsFailed(message));
  }
}

export function* watchFetchVersions(): any {
  yield takeEvery(GET_VERSIONS, fetchVersions);
}

export function* watchFetchAppliedVersions(): any {
  yield takeEvery(GET_APPLIED_VERSIONS, fetchAppliedVersions);
}

export function* watchDeployVersion(): any {
  yield takeEvery(DEPLOY_VERSION, putVersion);
}

function* versionsSaga(): any {
  yield all([fork(watchFetchVersions),
    fork(watchDeployVersion),
    fork(watchFetchAppliedVersions)]);
}

export default versionsSaga;
