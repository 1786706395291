// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import Portlet from '../../components/Portlet';
import StackedAreaChart from './StackedAreaChart';

import Spinner from '../../components/Spinner';
import { getCluster, getDashboard, updateCluster, updateDashboard } from '../../redux/cluster/actions';

const renderClusterHeader = cluster => {
  const { nodes, name } = cluster;
  return `${name.toUpperCase()} (${nodes.length})`;
};

const renderLoading = (loadingDashboard, loadingClusters) => {
  if (loadingClusters || loadingDashboard) {
    return (
      <Col
        lg={{ size: 12, offset: 5 }}
        md={{ size: 12, offset: 5 }}
        sm={{ size: 12, offset: 5 }}
        xs={{ size: 12, offset: 5 }}
        className="mt-5">
        <Spinner className="text-primary" type="grow" size="lg" />
      </Col>
    );
  }
  return null;
};

const renderPercentageMemory = charts => {
  const memoryData = charts.find(item => item.title === 'MEMORY');
  const { last } = memoryData.series[0];
  return `${last}GB`;
};

const renderPercentageCpu = charts => {
  const cpuData = charts.find(item => item.title === 'CPU');
  const { last } = cpuData.series[0];
  return `${last}%`;
};

const getRadiusSeries = charts => {
  const radius = charts.find(item => item.title === 'RADIUS ACCESS');
  const series = radius.series.filter(serie => serie.name === 'Access-Requests');
  return series;
};

const getAccountSeries = charts => {
  const radius = charts.find(item => item.title === 'RADIUS ACCOUNT');
  const series = radius.series.filter(serie => serie.name === 'Accounting-Requests');
  return series;
};

const xAxisLabelFormatter = (xAxisItem) => {
  if (!xAxisItem) {
    return '';
  }
  
  if (xAxisItem.split) {
    const arr = xAxisItem.split(' ');
    if (arr[1]) {
      return arr[1].concat('h');
    }
  }
  
  return xAxisItem;
}

const renderDashboard = (loadingDashboard, loadingClusters, dashboard) => {
  if (!loadingClusters && !loadingDashboard) {
    return (
      <Col lg={12} md={12}>
        {dashboard.length > 0 &&
          dashboard.map(item => (
            <Portlet header={renderClusterHeader(item.cluster)} key={item.cluster.id}>
              <Row>
                {item.cluster.nodes.map(node => (
                  <Col key={node.id} lg={6} md={6}>
                    <Link to={`/dashboard/${item.cluster.id}/${node.id}`}>
                      <Card>
                        <CardBody>
                          <CardTitle>
                            <h5>{node.name}</h5>
                          </CardTitle>
                          <div>
                            <Row>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                <h4>Memory: {renderPercentageMemory(node.charts)}</h4>
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                <h4>CPU: {renderPercentageCpu(node.charts)}</h4>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col>
                              <StackedAreaChart
                                title={getRadiusSeries(node.charts)[0].name}
                                series={getRadiusSeries(node.charts)[0]}
                                height={250}
                                xAxisLabelFormatter={xAxisLabelFormatter}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <StackedAreaChart
                                title={getAccountSeries(node.charts)[0].name}
                                series={getAccountSeries(node.charts)[0]}
                                height={250}
                                xAxisLabelFormatter={xAxisLabelFormatter}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Portlet>
          ))}
      </Col>
    );
  }
  return null;
};

const StatusPage = () => {
  const dispatch = useDispatch();
  const { dashboard, loadingClusters, loadingDashboard } = useSelector(state => state.Cluster);

  useEffect(() => {
    const fetchDashboard = () => {
      dispatch(getCluster());
      dispatch(getDashboard());
    };
    fetchDashboard();
    const updateInterval = setInterval(() => {
      dispatch(updateCluster());
      dispatch(updateDashboard());
    }, 60000);
    const updateDashboardClusters = () => updateInterval;
    updateDashboardClusters();
    return () => {
      clearInterval(updateInterval);
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const handleAuthentication = async () => {
  //     await auth0Client.handleAuthentication();
  //     dispatch(loginUser(auth0Client.getProfile()));
  //   };
  //   handleAuthentication();
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Status</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            {renderLoading(loadingDashboard, loadingClusters)}
            {renderDashboard(loadingDashboard, loadingClusters, dashboard)}
            <Col lg={6} md={6} />
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StatusPage;
