// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { LOAD_NODES_CONTROL,
  NODES_CONTROL_START,
  NODES_CONTROL_STOP } from './constants';

import { loadNodesControl,
  loadNodesControlSuccess, 
  loadNodesControlFailed,
  nodesControlStartFailed,
  nodesControlStopFailed} from './actions-nodes-control';

function* fetchNodesControl() {
  try {
    const response = yield call(fetchJSON, '/nodes/status');
    yield put(loadNodesControlSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(loadNodesControlFailed(message));
  }
}

function* startNode(actionCreator) {
  try {
    const { params } = actionCreator;
    yield call(fetchJSON, `/nodes/${params.nodeId}/start`, { method: 'POST' });
    yield put(loadNodesControl());
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(nodesControlStartFailed(message));
  }
}

function* stopNode(actionCreator) {
  try {
    const { params } = actionCreator;
    yield call(fetchJSON, `/nodes/${params.nodeId}/stop`, { method: 'POST' });
    yield put(loadNodesControl());
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(nodesControlStopFailed(message));
  }
}

export function* watchFetchNodesControl(): any {
  yield takeEvery(LOAD_NODES_CONTROL, fetchNodesControl);
}

export function* watchStartNode(): any {
  yield takeEvery(NODES_CONTROL_START, startNode);
}

export function* watchStopNode(): any {
  yield takeEvery(NODES_CONTROL_STOP, stopNode);
}

function* operationsSaga(): any {
  yield all([fork(watchFetchNodesControl),
    fork(watchStartNode),
    fork(watchStopNode)]);
}

export default operationsSaga;