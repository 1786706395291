// @flow
import {
  GET_VERSIONS,
  GET_VERSIONS_SUCCESS,
  GET_VERSIONS_FAILED,
  DEPLOY_VERSION,
  DEPLOY_VERSION_SUCCESS,
  DEPLOY_VERSION_FAILED,
  GET_APPLIED_VERSIONS,
  GET_APPLIED_VERSIONS_SUCCESS,
  GET_APPLIED_VERSIONS_FAILED
} from './constants';

type VersionsAction = { type: string, payload: {} | string | Array<Object> | Object };

export const getVersions = (): VersionsAction => ({
  type: GET_VERSIONS,
  payload: {},
});

export const getVersionsSuccess = (versions: Array<Object>): VersionsAction => ({
  type: GET_VERSIONS_SUCCESS,
  payload: versions,
});

export const getVersionsFailed = (error: string): VersionsAction => ({
  type: GET_VERSIONS_FAILED,
  payload: error,
});

export const deployVersion = (nodeAndVersion: Object): VersionsAction => ({
  type: DEPLOY_VERSION,
  payload: nodeAndVersion,
});

export const deployVersionSuccess = (): VersionsAction => ({
  type: DEPLOY_VERSION_SUCCESS,
  payload: {},
});

export const deployVersionFailed = (error: string): VersionsAction => ({
  type: DEPLOY_VERSION_FAILED,
  payload: error,
});

export const getAppliedVersions = (): VersionsAction => ({
  type: GET_APPLIED_VERSIONS,
  payload: {},
});

export const getAppliedVersionsSuccess = (versions: Object): VersionsAction => ({
  type: GET_APPLIED_VERSIONS_SUCCESS,
  payload: versions,
});

export const getAppliedVersionsFailed = (error: string): VersionsAction => ({
  type: GET_APPLIED_VERSIONS_FAILED,
  payload: error,
});
