import { compose, filter, toPairs, join, map } from 'ramda';
import { SERVER_BASE_URL } from '../config';
import Auth from './AuthSingleton';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (path, options = {}) => {
  const myOptions = { ...options, headers: { Authorization: `Bearer ${Auth.getIdToken()}` } };
  return fetch(`${SERVER_BASE_URL}${path}`, myOptions)
    .then(response => {
      // if (response.status < 200 || response.status > 299) {
      //   throw response.json();
      // }
      return response.json().catch(() => null);
    })
    .then(json => {
      if (json && json.errorMessage) {
        throw json;
      }
      return json;
    })
    .catch(error => {
      console.log('error>>>', error);
      throw error;
    });
};

/**
 * Builds a query string from an object
 *
 * @param {Object} params - Query params
 * @param {String} The encoded query string
 */
const buildQueryParamsSearch = compose(
  queryString => (queryString ? `?${queryString}` : ''),
  join('&'),
  map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`),
  toPairs,
  filter(param => param)
);

export { fetchJSON, buildQueryParamsSearch };
