import React from 'react';
import { Alert } from 'reactstrap';

const ErrorAlert = (props) => {
  if (!props.error) {
    return null;
  }

  return (
    <Alert color="danger">
      {parseError(props.error)}
    </Alert>
  );
}

function parseError(error) {
  if (typeof error === 'string') {
    return error;
  }

  if (typeof error === 'object' && Object.keys(error).length > 0) {
    return (
      <ul>
        {Object.keys(error).map(k => <li><b>{k}: </b>{error[k]}</li>)}
      </ul>
    )
  }

  return "Unexpected error";
}

export default ErrorAlert;