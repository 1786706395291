// @flow
import {
  GET_CLUSTER,
  UPDATE_CLUSTER,
  GET_CLUSTER_SUCCESS,
  GET_CLUSTER_FAILED,
  GET_DASHBOARD,
  UPDATE_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILED,
  ENABLE_EDIT_MODE,
  EXIT_EDIT_MODE,
  ADD_PROPERTY,
  DELETE_PROPERTY,
  SAVE_VERSION,
  SAVE_VERSION_SUCCESS,
  SAVE_VERSION_FAILED,
  ADD_CLUSTER,
  ADD_NAS_CLIENT,
  DELETE_NAS_CLIENT,
} from './constants';

const INIT_STATE = {
  clusters: [],
  dashboard: [],
  nodesMapped: null,
  loadingClusters: false,
  loadingDashboard: false,
  loadingSaveVersion: false,
  isEditMode: false,
  errorAuthenticate: false,
  errorSaveVersion: false,
  showModal: false,
};

type ClusterAction = { type: string, payload: {} | string };
type State = { clusters?: [] | null, loading?: boolean, +value ?: boolean };

const getNodesFromClusters = payload => {
  const clustersData = payload.data;
  const nodes = {};

  if (!clustersData || !Array.isArray(clustersData)) {
    return nodes;
  }

  clustersData.forEach(it => {
    const cluster = it.cluster;
    if (cluster.nodes && Array.isArray(cluster.nodes)) {
      cluster.nodes.forEach(node => {
        node.clusterName = cluster.name;
        nodes[node.id] = node;
        nodes[node.id.replace(/-/g, '')] = node;
      });
    }
  });

  return nodes;
};

const Cluster = (state: State = INIT_STATE, action: ClusterAction) => {
  switch (action.type) {
    case GET_CLUSTER:
      return { ...state, loadingClusters: true };
    case UPDATE_CLUSTER:
      return { ...state, loadingClusters: false };
    case GET_CLUSTER_SUCCESS:
      return {
        ...state,
        clusters: action.payload,
        nodesMapped: getNodesFromClusters(action.payload),
        loadingClusters: false,
        error: null,
      };
    case GET_CLUSTER_FAILED:
      return { ...state, error: action.payload, loadingClusters: false };
    case GET_DASHBOARD:
      return { ...state, loadingDashboard: true };
    case UPDATE_DASHBOARD:
      return { ...state, loadingDashboard: false };
    case GET_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.payload, loadingDashboard: false, error: null };
    case GET_DASHBOARD_FAILED:
      return { ...state, error: action.payload, loadingDashboard: false };
    case ENABLE_EDIT_MODE:
      window.isEditMode = true;
      return { ...state, isEditMode: true, errorSaveVersion: false };
    case EXIT_EDIT_MODE:
      window.isEditMode = false;
      return { ...state, isEditMode: false, errorSaveVersion: false };
    case SAVE_VERSION:
      return { ...state, loadingSaveVersion: true };
    case SAVE_VERSION_SUCCESS:
      window.isEditMode = false;
      return { ...state, loadingSaveVersion: false, isEditMode: false, errorSaveVersion: false };
    case SAVE_VERSION_FAILED:
      return { ...state, loadingSaveVersion: false, errorSaveVersion: action.payload };
    case ADD_PROPERTY:
    case DELETE_PROPERTY:
    case ADD_CLUSTER:
    case ADD_NAS_CLIENT:
    case DELETE_NAS_CLIENT:
      return {
        ...state,
        clusters: action.payload,
        nodesMapped: getNodesFromClusters(action.payload),
      };
    default:
      return { ...state };
  }
};

export default Cluster;
