import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

// layout HOC
import withLayout from '../components/Layout';
import { allFlattenRoutes as routes } from './index';
import AuthCallback from '../pages/AuthCallback';
import Logout from '../pages/auth/Logout';
import Unauthorized from '../pages/Unauthorized';

const Routes = () => (
  // rendering the router with layout
  <HashRouter>
    <Route path="/" component={AuthCallback} />

    {/* Renders while starter is loading user data */}
    <Route path="/" component={() => null} exact />

    <Route path="/account/logout" component={Logout} exact />
    <Route path="/unauthorized" component={Unauthorized} exact />
    <Switch>
      {routes.map((route, index) => {
        return (
          !route.children && (
            <route.route
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              path={route.path}
              roles={route.roles}
              exact={route.exact}
              component={withLayout(props => {
                return <route.component {...props} />;
              })}
            />
          )
        );
      })}
    </Switch>
  </HashRouter>
);

export default Routes;
