import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import img from '../assets/images/maintenance.svg';

const Unauthorized = props => (
  <div className="mt-5 mb-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="text-center">
            <img src={img} height="140" alt="Unauthorized" />
            <h3 className="mt-4">you don't have access</h3>
            <p className="text-muted">contact the administrator</p>
            <Button
              outline
              color="primary"
              onClick={() => {
                props.history.replace('/account/logout');
              }}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const UnauthorizedR = withRouter(Unauthorized);

export default UnauthorizedR;
