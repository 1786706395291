// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
  GET_CLUSTER,
  GET_DASHBOARD,
  UPDATE_CLUSTER,
  UPDATE_DASHBOARD,
  SAVE_VERSION,
  EXIT_EDIT_MODE,
} from './constants';

import {
  getDashboard,
  getCluster,
  getClusterSuccess,
  getClusterFailed,
  getDashboardSuccess,
  getDashboardFailed,
  saveVersionSuccess,
  saveVersionFailed,
} from './actions';

/**
 * fetch the clusters data
 */
function* fetchClustersDashboard() {
  try {
    const response = yield call(fetchJSON, '/status');
    yield put(getDashboardSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getDashboardFailed(message));
  }
}

/**
 * fetch the clusters data
 */
function* fetchClusters() {
  try {
    const response = yield call(fetchJSON, '/clusters');
    yield put(getClusterSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getClusterFailed(message));
  }
}

/**
 * Save the new version of clusters
 * @param {*} payload - clusters
 */
function* postVersion({ payload }) {
  const options = {
    body: JSON.stringify(payload),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    yield call(fetchJSON, '/clusters', options);
    yield put(saveVersionSuccess());
    yield put(getCluster());
    yield put(getDashboard());
  } catch (error) {
    let message;
    switch (error.status) {
      case 400:
        message = 'Clusters radius host must be a ip.';
        break;
      case 500:
      case 422:
        message = 'Internal Server Error';
        break;
      case 403:
        message = 'Invalid credentials';
        break;
      default:
        message = error.errorMessage;
    }
    yield put(saveVersionFailed(message));
  }
}

export function* watchFetchClusters(): any {
  yield takeEvery(GET_CLUSTER, fetchClusters);
}

export function* watchFetchClustersDashboard(): any {
  yield takeEvery(GET_DASHBOARD, fetchClustersDashboard);
}

export function* watchUpdateClusters(): any {
  yield takeEvery(UPDATE_CLUSTER, fetchClusters);
}

export function* watchUpdateClustersDashboard(): any {
  yield takeEvery(UPDATE_DASHBOARD, fetchClustersDashboard);
}

export function* watchExitEditMode(): any {
  yield takeEvery(EXIT_EDIT_MODE, fetchClusters);
}

export function* watchSaveVersion(): any {
  yield takeEvery(SAVE_VERSION, postVersion);
}

function* clusterSaga(): any {
  yield all([
    fork(watchFetchClusters),
    fork(watchFetchClustersDashboard),
    fork(watchUpdateClusters),
    fork(watchUpdateClustersDashboard),
    fork(watchSaveVersion),
    fork(watchExitEditMode),
  ]);
}

export default clusterSaga;
