/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow
import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import uuidv1 from 'uuid/v1';
import { addProperty, deleteProperty } from '../../redux/actions';
import UserRoleChecker from '../../components/UserRoleChecker';

const Properties = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.Cluster.clusters);
  const { clusters } = useSelector(state => state.Cluster);
  const { isEditMode } = useSelector(state => state.Cluster);
  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({});
  const [clusterId, setClusterId] = useState({ cluster: data[0].cluster.id });
  const [errors, setErrors] = useState({});
  const [configs, setConfigs] = useState(data[0].cluster.configs);
  const [passwordShown, setPasswordShown] = useState(true);

  const toggle = async () => {
    await setModal(!modal);
    if (modal) {
      setInputs({});
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    const errors = {};

    if (!inputs.key) {
      formIsValid = false;
      errors.key = 'Cannot be empty';
    }

    if (!inputs.value) {
      formIsValid = false;
      errors.value = 'Cannot be empty';
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleEdit = property => {
    setInputs(property);
    toggle();
    setPasswordShown(!propertySecret(property));
  };

  const handleDelete = property => {
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId.cluster);
    const index = configs.indexOf(property);
    if (index > -1) {
      // configs.splice(index, 1);
      cluster.cluster.configs.splice(index, 1);
      setConfigs(cluster.cluster.configs);
    }
    dispatch(deleteProperty(clustersClone));
  };

  const handleSearch = e => {
    e.preventDefault();
    const cluster = data.find(cluster => cluster.cluster.id === clusterId.cluster);
    if (cluster) {
      return setConfigs(cluster.cluster.configs || []);
    }
    return setConfigs([]);
  };

  const saveProperty = clustersClone => {
    dispatch(addProperty(clustersClone));
    setInputs({});
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId.cluster);

    if (handleValidation()) {
      if (inputs.id) {
        const configsEdited = cluster.cluster.configs.map(config => {
          if (config.id === inputs.id) {
            config = inputs;
          }
          return config;
        });
        cluster.cluster.configs = configsEdited;
      } else if (cluster.cluster.configs) {
        cluster.cluster.configs.push({ ...inputs, id: uuidv1() });
      } else {
        cluster.cluster.configs = [inputs];
      }
      saveProperty(clustersClone);
      return setConfigs(cluster.cluster.configs);
    }
    return console.log('errors>>>', errors);
  };

  const formatPropertyValue = property => {
    if (property.value.includes('http')) {
      return property.value.replace('http://','');
    }
    if (property.value.includes('https')) {
      return property.value.replace('https://','');
    }
    if (property && property.key &&
      (property.key.toUpperCase().includes('KEY') || property.key.toUpperCase().includes('_ID'))) {
      return 'XXXXXX';
    }
    return property.value;
  }

  const propertySecret = property => {
    if ( property && property.key &&
      (property.key.toUpperCase().includes('_KEY') || property.key.toUpperCase().includes('_ID'))) {
      return true;
    }
    return false;
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Property</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Form>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    name="key"
                    id="key"
                    value={inputs.key}
                  />
                  <FormText color="danger" className="mb-2">
                    {errors.key}
                  </FormText>
                  <Label for="value">Value</Label>
                  <div className="input-group">
                    <Input
                      type={passwordShown ? "text" : "password"}
                      className="mb-2"
                      name="value"
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      id="value"
                      value={inputs.value}
                    />
                    {(!passwordShown || propertySecret(inputs)) && inputs.id &&
                      <i onClick={togglePasswordVisiblity} className="mdi mdi-eye" style={{fontSize: "1.7em", marginLeft: "8px"}}></i>
                    }
                  </div>
                  <FormText color="danger" className="mb-2">
                    {errors.key}
                  </FormText>
                  <Label for="value">Description</Label>
                  <Input
                    type="text"
                    className="mb-2"
                    name="description"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    id="description"
                    value={inputs.description}
                  />
                  <FormText color="danger" className="mb-2">
                    {errors.description}
                  </FormText>
                  {/* <Label for="cluster">Cluster</Label>
                  <Input type="select" className="form-control" name="cluster" id="cluster">
                    {data.map(cluster => (
                      <option value={cluster.cluster.id}>{cluster.cluster.name}</option>
                    ))}
                  </Input> */}
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button onClick={e => handleSubmit(e)} color="primary">
            Save Property
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Properties</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form onSubmit={e => e.preventDefault()} inline>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="action" className="mr-2" md={1}>
                      Cluster:
                    </Label>
                    <Input
                      type="select"
                      style={{ minWidth: '200px' }}
                      onChange={e => setClusterId({ ...clusterId, [e.target.name]: e.target.value })}
                      className="form-control"
                      name="cluster"
                      id="cluster">
                      {data.map((cluster, index) => (
                        <option key={index} value={cluster.cluster.id}>{cluster.cluster.name}</option>
                      ))}
                    </Input>
                    <Button onClick={e => handleSearch(e)} color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col lg={10} sm={10} md={10}>
                    <h4>The list of Properties</h4>
                  </Col>
                  <UserRoleChecker mustBeAdmin={true}>
                    <Col lg={2} sm={2} md={2}>
                      <Button disabled={!isEditMode} color="primary" onClick={toggle}>
                        Add New Property
                      </Button>
                    </Col>
                  </UserRoleChecker>
                </Row>
              </CardTitle>
              <Row>
                <Col lg={12} md={12}>
                  {configs.length === 0 && (
                    <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
                  )}
                  {configs.length > 0 && (
                    <Table striped>
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th>Name</th>
                          <th>Value</th>
                          <th>Description</th>
                          <th className={classNames({ hide: !isEditMode })}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {configs.map((property, index) => (
                          <tr key={index} style={{ textAlign: 'center' }}>
                            <td>{property.key}</td>
                            <td className={propertySecret(property) ? 'table-secret-key' : null }>{formatPropertyValue(property)}</td>
                            <td>{property.description || '---'}</td>
                            <td className={classNames({ hide: !isEditMode }, 'table-action')}>
                              <button className="action-icon clear-background clear-border">
                                <i
                                  role="button"
                                  onClick={() => handleEdit(property)}
                                  onKeyPress={() => handleEdit(property)}
                                  className="mdi mdi-pencil"
                                />
                              </button>
                              <button className="action-icon clear-background clear-border">
                                <i
                                  role="button"
                                  onClick={() => handleDelete(property)}
                                  onKeyPress={() => handleDelete(property)}
                                  className="mdi mdi-delete"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Properties;
