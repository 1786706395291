// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';

import {
  LOAD_TRACES_MAC_LIST,
  DELETE_MAC_FROM_TRACE,
  ADD_MAC_TO_TRACE,
  SEARCH_TRACES } from './constants';

import { 
  searchTracesSuccess, 
  searchTracesFailed, 
  addMacToTraceSuccess, 
  addMacToTraceFailed,
  deleteMacFromTraceSuccess,
  deleteMacFromTraceFailed,
  loadTracesMacListSuccess,
  loadTracesMacListFailed } from './actions-traces';

function* loadTracesMacList(actionCreator) {
  try {
    const response = yield call(fetchJSON, "/traces");
    yield put(loadTracesMacListSuccess(response));
  } catch (error) {
    if (error.statusCode === 404) {
      yield put(loadTracesMacListSuccess(null));
      return;
    }
    
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(loadTracesMacListFailed(message));
  }
}

function* addMacToTrace(actionCreator) {
  try {
    const { params } = actionCreator;
    const body = JSON.stringify({ macAddress: params.macAddress });
    const response = yield call(fetchJSON, "/traces", { method: "POST", body });
    yield put(addMacToTraceSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(addMacToTraceFailed(message));
  }
}

function* deleteMacFromTrace(actionCreator) {
  try {
    const { params } = actionCreator;
    yield call(fetchJSON, `/traces/${params.macId}`, { method: "DELETE" });
    yield put(deleteMacFromTraceSuccess(params));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(deleteMacFromTraceFailed(message));
  }
}

function* searchTraces(actionCreator) {
  try {
    const { params } = actionCreator;
    const response = yield call(fetchJSON, `/traces/macaddress/${params.searchMac}`);
    yield put(searchTracesSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(searchTracesFailed(message));
  }
}

export function* watchLoadTracesMacList(): any {
  yield takeEvery(LOAD_TRACES_MAC_LIST, loadTracesMacList);
}

export function* watchAddMacToTrace(): any {
  yield takeEvery(ADD_MAC_TO_TRACE, addMacToTrace);
}

export function* watchDeleteMacToTrace(): any {
  yield takeEvery(DELETE_MAC_FROM_TRACE, deleteMacFromTrace);
}

export function* watchSearchTraces(): any {
  yield takeEvery(SEARCH_TRACES, searchTraces);
}

function* operationsTraceSaga(): any {
  yield all([fork(watchAddMacToTrace),
    fork(watchDeleteMacToTrace),
    fork(watchSearchTraces),
    fork(watchLoadTracesMacList)]);
}

export default operationsTraceSaga;
