/* CLUSTER */
export const GET_VERSIONS = 'GET_VERSIONS';
export const GET_VERSIONS_SUCCESS = 'GET_VERSIONS_SUCCESS';
export const GET_VERSIONS_FAILED = 'GET_VERSIONS_FAILED';
export const DEPLOY_VERSION = 'DEPLOY_VERSION';
export const DEPLOY_VERSION_SUCCESS = 'DEPLOY_VERSION_SUCCESS';
export const DEPLOY_VERSION_FAILED = 'DEPLOY_VERSION_FAILED';
export const GET_APPLIED_VERSIONS = 'GET_APPLIED_VERSIONS';
export const GET_APPLIED_VERSIONS_SUCCESS = 'GET_APPLIED_VERSIONS_SUCCESS';
export const GET_APPLIED_VERSIONS_FAILED = 'GET_APPLIED_VERSIONS_FAILED';
