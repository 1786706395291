/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Table, Button } from 'reactstrap';
import moment from 'moment';
import { getAudit, searchAudit } from '../../redux/actions';
import Spinner from '../../components/Spinner';
import HyperDatepicker from '../../components/Datepicker';
import ErrorAlert from './ErrorAlert';

const renderAudit = (audit: Array<Object>, loadingAudit: Boolean) => {
  if (audit.length > 0 && !loadingAudit) {
    return (
      <Col>
        <Card>
          <CardBody>
            <p className="text-muted font-14 mb-4">Your search returned the following result.</p>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Login</th>
                  <th>IP</th>
                  <th>Category</th>
                  <th>Action</th>
                  <th>Message</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {audit.map(auditItem => (
                  <tr key={auditItem.id}>
                    <td>{auditItem.login}</td>
                    <td>{auditItem.ip}</td>
                    <td>{auditItem.category}</td>
                    <td>{auditItem.action}</td>
                    <td>{auditItem.message}</td>
                    <td>{new Date(auditItem.created_date).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
  if (audit.length === 0 && !loadingAudit) {
    return (
      <Col>
        <Card>
          <CardBody>
            <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return null;
};

const renderLoading = (loadingAudit: Boolean) => {
  if (loadingAudit) {
    return (
      <Col
        lg={{ size: 12, offset: 5 }}
        md={{ size: 12, offset: 5 }}
        sm={{ size: 12, offset: 5 }}
        xs={{ size: 12, offset: 5 }}
        className="mt-5">
        <Spinner className="text-primary" type="grow" size="lg" />
      </Col>
    );
  }
  return null;
};

const Audit = () => {
  const dispatch = useDispatch();
  const { audit, loadingAudit, error } = useSelector(state => state.Operations);
  const [inputs, setInputs] = useState({ action: null, login: null, dateFrom: null, dateTo: null });

  useEffect(() => {
    dispatch(getAudit());
  }, [dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    const params = {
      ...inputs,
      dateFrom: inputs.dateFrom ? moment(inputs.dateFrom).format('YYYY-MM-DD') : null,
      dateTo: inputs.dateTo ? moment(inputs.dateTo).format('YYYY-MM-DD') : null,
    };
    dispatch(searchAudit(params));
  };

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Audit</h4>
          </div>
        </Col>
      </Row>
      <ErrorAlert error={error}/>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form inline onSubmit={e => handleSubmit(e)} autocomplete="off">
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="action" className="mr-2" md={1}>
                      Action:
                    </Label>
                    <Input
                      type="text"
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      className="form-control"
                      name="action"
                      id="action"
                    />
                    <Label for="parameter" className="mr-2" md={1}>
                      Login:
                    </Label>
                    <Input
                      type="text"
                      name="login"
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      className="form-control"
                      id="login"
                    />
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="dateFrom" className="mr-2" md={1}>
                      From:
                    </Label>
                    <HyperDatepicker
                      id="dateFrom"
                      hideAddon
                      dateFormat="yyyy-MM-dd"
                      selected={inputs.dateFrom}
                      onChange={date => setInputs({ ...inputs, dateFrom: date })}
                    />
                    <Label for="dateTo" className="mr-2 text-left" md={1}>
                      To:
                    </Label>
                    <HyperDatepicker
                      id="dateTo"
                      hideAddon
                      dateFormat="yyyy-MM-dd"
                      selected={inputs.dateTo}
                      onChange={date => setInputs({ ...inputs, dateTo: date })}
                    />
                    <Button color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {renderAudit(audit, loadingAudit)}
        {renderLoading(loadingAudit)}
      </Row>
    </>
  );
};

export default Audit;
