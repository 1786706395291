// @flow
import {
  GET_AUDIT,
  GET_AUDIT_SUCCESS,
  GET_AUDIT_FAILED,
  SEARCH_AUDIT,
  SEARCH_AUDIT_SUCCESS,
  SEARCH_AUDIT_FAILED,
} from './constants';

const INIT_STATE = {
  audit: [],
  loadingAudit: false,
};

type AuditAction = { type: string, payload: {} | string };
type State = { audit?: [] | null, loadingAudit?: boolean, +value?: boolean };

const Operations = (state: State = INIT_STATE, action: AuditAction) => {
  switch (action.type) {
    case GET_AUDIT:
      return { ...state, loadingAudit: true };
    case GET_AUDIT_SUCCESS:
      return { ...state, audit: action.payload, loadingAudit: false, error: null };
    case GET_AUDIT_FAILED:
      return { ...state, error: action.payload, loadingAudit: false };
    case SEARCH_AUDIT:
      return { ...state, loadingAudit: true };
    case SEARCH_AUDIT_SUCCESS:
      return { ...state, audit: action.payload, loadingAudit: false, error: null };
    case SEARCH_AUDIT_FAILED:
      return { ...state, error: action.payload, loadingAudit: false };
    default:
      return { ...state };
  }
};

export default Operations;
