// @flow
import {
  SEARCH_LOGS,
  SEARCH_LOGS_SUCCESS,
  SEARCH_LOGS_FAILED
} from './constants';

const INIT_STATE = {
  logs: null,
  loadingLogs: false,
};

type LogsAction = { type: string, payload: {} | string };
type State = { logs?: {} | null, loadingLogs?: boolean, +value?: boolean };

const OperationsLogs = (state: State = INIT_STATE, action: LogsAction) => {
  switch (action.type) {
    case SEARCH_LOGS:
      return { ...state, loadingLogs: true };
    case SEARCH_LOGS_SUCCESS:
      return { ...state, logs: action.payload, loadingLogs: false, error: null };
    case SEARCH_LOGS_FAILED:
      return { ...state, error: action.payload, loadingLogs: false };
    
    default:
      return { ...state };
  }
};

export default OperationsLogs;
