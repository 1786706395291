import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// integrations
import NasClients from '../pages/integrations/NasClients';
import RadiusGroup from '../pages/integrations/RadiusGroup';
import RadiusServer from '../pages/integrations/RadiusServer';
// dashboard
import StatusDashboard from '../pages/dashboards';
import NodeDetails from '../pages/dashboards/NodeDetails';
import Versions from '../pages/versions';
// apps
import AuthCallback from '../pages/AuthCallback';
import Unauthorized from '../pages/Unauthorized';
// operations
import About from '../pages/operations/About';
import Audit from '../pages/operations/Audit';
import Sessions from '../pages/operations/Sessions';
import Traces from '../pages/operations/Traces';
import Logs from '../pages/operations/Logs';
import NodesControl from '../pages/operations/NodesControl';
// system
import Properties from '../pages/system/Properties';
import Clusters from '../pages/system/Clusters';
import Nodes from '../pages/system/Nodes';

// handle auth and authorization

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { user } = useSelector(state => state.Auth);

  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (
      <Route
        {...rest}
        render={() => {
          return <h3>Please wait</h3>;
        }}
      />
    );
  }

  if (user && !user.roles.hasMobiAaaRole) {
    return (
      <Route
        {...rest}
        render={() => {
          return <Unauthorized />;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard/status',
  name: 'Status',
  icon: 'dripicons-pulse',
  header: 'Navigation',
  component: StatusDashboard,
  route: PrivateRoute,
};

const dashboardIdRoutes = {
  path: '/dashboard/:clusterId/:nodeId',
  route: PrivateRoute,
  component: NodeDetails,
  exact: true,
};

const integrationsIdRoutes = {
  path: '/integrations/:clusterId/:radiusGroupId',
  component: RadiusServer,
  route: PrivateRoute,
  exact: true,
};

const systemIdRoutes = {
  path: '/system/clusters/:clusterId/nodes',
  component: Nodes,
  route: PrivateRoute,
  exact: true,
};

const starterRoute = {
  path: '/callback',
  route: Route,
  component: AuthCallback,
  exact: true,
};

const versionRoutes = {
  path: '/versions',
  name: 'Versions',
  icon: 'dripicons-duplicate',
  component: Versions,
  route: PrivateRoute,
};

// apps
const systemRoutes = {
  path: '/system',
  name: 'System',
  icon: 'dripicons-view-thumb',
  children: [
    {
      path: '/system/properties',
      name: 'Properties',
      component: Properties,
      route: PrivateRoute,
    },
    {
      path: '/system/clusters',
      name: 'Clusters',
      component: Clusters,
      route: PrivateRoute,
    },
  ],
};

// pages
const pageRoutes = {
  path: '/integrations',
  name: 'Integrations',
  icon: 'dripicons-network-3',
  children: [
    {
      path: '/integrations/clients',
      name: 'Nas Clients',
      component: NasClients,
      route: PrivateRoute,
    },
    {
      path: '/integrations/radiusGroup',
      name: 'Radius Group',
      component: RadiusGroup,
      route: PrivateRoute,
    },
  ],
};

// operations
const operationsRoutes = {
  path: '/operations',
  name: 'Operations',
  icon: 'dripicons-view-apps',
  children: [
    {
      path: '/operations/nodes-control',
      name: 'Nodes Control',
      component: NodesControl,
      route: PrivateRoute,
    },
    {
      path: '/operations/sessions',
      name: 'Sessions',
      component: Sessions,
      route: PrivateRoute,
    },
    {
      path: '/operations/traces',
      name: 'Traces',
      component: Traces,
      route: PrivateRoute,
    },
    {
      path: '/operations/audit',
      name: 'Audit',
      component: Audit,
      route: PrivateRoute,
    },
    {
      path: '/operations/log',
      name: 'Logs',
      component: Logs,
      route: PrivateRoute,
    },
    {
      path: '/operations/about',
      name: 'About',
      component: About,
      route: PrivateRoute,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [
  // rootRoute,
  dashboardRoutes,
  dashboardIdRoutes,
  integrationsIdRoutes,
  systemIdRoutes,
  starterRoute,
  systemRoutes,
  versionRoutes,
  pageRoutes,
  operationsRoutes,
];

const authProtectedRoutes = [dashboardRoutes, versionRoutes, systemRoutes, pageRoutes, operationsRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
