// @flow
import {
  GET_AUDIT,
  GET_AUDIT_SUCCESS,
  GET_AUDIT_FAILED,
  SEARCH_AUDIT,
  SEARCH_AUDIT_SUCCESS,
  SEARCH_AUDIT_FAILED,
} from './constants';

type AuditAction = { type: string, payload: {} | string | Array<Object> };

export const getAudit = (): AuditAction => ({
  type: GET_AUDIT,
  payload: {},
});

export const getAuditSuccess = (audit: Array<Object>): AuditAction => ({
  type: GET_AUDIT_SUCCESS,
  payload: audit,
});

export const getAuditFailed = (error: string): AuditAction => ({
  type: GET_AUDIT_FAILED,
  payload: error,
});

export const searchAudit = (params: Object): AuditAction => ({
  type: SEARCH_AUDIT,
  payload: {},
  params,
});

export const searchAuditSuccess = (audit: Array<Object>): AuditAction => ({
  type: SEARCH_AUDIT_SUCCESS,
  payload: audit,
});

export const searchAuditFailed = (error: string): AuditAction => ({
  type: SEARCH_AUDIT_FAILED,
  payload: error,
});
