/* CLUSTER */
export const GET_CLUSTER = 'GET_CLUSTER';
export const GET_CLUSTER_SUCCESS = 'GET_CLUSTER_SUCCESS';
export const GET_CLUSTER_FAILED = 'GET_CLUSTER_FAILED';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED';
export const UPDATE_CLUSTER = 'UPDATE_CLUSTER';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const ENABLE_EDIT_MODE = 'ENABLE_EDIT_MODE';
export const EXIT_EDIT_MODE = 'EXIT_EDIT_MODE';
export const SAVE_VERSION = 'SAVE_VERSION';
export const SAVE_VERSION_SUCCESS = 'SAVE_VERSION_SUCCESS';
export const SAVE_VERSION_FAILED = 'SAVE_VERSION_FAILED';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const ADD_CLUSTER = 'ADD_CLUSTER';
export const DELETE_CLUSTER = 'DELETE_CLUSTER';
export const ADD_NAS_CLIENT = 'ADD_NAS_CLIENT';
export const DELETE_NAS_CLIENT = 'DELETE_NAS_CLIENT';
