// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import clusterSaga from './cluster/saga';
import versionsSaga from './versions/saga';
import operationsSaga from './operations/saga';
import operationsTraceSaga from './operations/saga-traces';
import operationsNodesControl from './operations/saga-nodes-control';

// eslint-disable-next-line no-unused-vars
export default function* rootSaga(getState: any): any {
  yield all([authSaga(), layoutSaga(), appMenuSaga(), clusterSaga(), versionsSaga(), operationsSaga(), operationsTraceSaga(), operationsNodesControl()]);
}
