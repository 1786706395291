import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { fetchJSON } from '../helpers/api';
import Auth from '../helpers/AuthSingleton';
import { loginUser } from '../redux/auth/actions';
import Unauthorized from './Unauthorized';

// const callbackError = window.location.search.includes('error=invalid_request');
const callbackError = false;

async function fetchToken(isLoading, setToken, getIdTokenClaims, history) {
  if (isLoading) {
    return;
  }
  const res = await getIdTokenClaims();

  if (res) {
    // eslint-disable-next-line no-underscore-dangle
    const accessToken = res.__raw;

    Auth.setIdToken(accessToken);
    setToken(accessToken);

    history.replace('/dashboard/status');
  }
}

async function fetchUserData(props, token, loginUser, setError2, setInitialized) {
  if (!token) {
    return;
  }

  try {
    const user = await fetchJSON('/me');
    loginUser(user);
    setInitialized(true);
  } catch (err) {
    setError2(err);
  }
}

function AuthCallback(props) {
  const { isLoading, error, isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0();
  const [token, setToken] = useState(null);
  const [error2, setError2] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();
  const { loginUser } = props;

  useEffect(() => {
    if (!initialized && !callbackError) {
      fetchToken(isLoading, setToken, getIdTokenClaims, history);
    }
  }, [isLoading, getIdTokenClaims, history, initialized]);

  useEffect(() => {
    if (!initialized && !callbackError) {
      fetchUserData(props, token, loginUser, setError2, setInitialized);
    }
  }, [props, token, loginUser, setError2, initialized, setInitialized]);

  if (!isLoading && !isAuthenticated && !callbackError) {
    loginWithRedirect();
    return null;
  }

  if (error || error2 || callbackError) {
    return <Unauthorized />;
  }

  return null;
}

export default connect(null, { loginUser })(AuthCallback);
