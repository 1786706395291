// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, CardTitle, Table } from 'reactstrap';
import { getCluster, getDashboard, updateCluster, updateDashboard } from '../../redux/cluster/actions';
import StackedAreaChart from './StackedAreaChart';
import PageTitle from '../../components/PageTitle';

const xAxisLabelFormatter = (xAxisItem) => {
  if (xAxisItem.split) {
    const arr = xAxisItem.split(' ');
    if (arr[1]) {
      return arr[1].concat(':00 UTC');
    }
  }
  return xAxisItem;
}

const NodeDetails = (props: Object) => {
  const { dashboard, clusters } = useSelector(state => state.Cluster);
  const dispatch = useDispatch();
  const [node, setNode] = useState({ name: '' });
  const [charts, setCharts] = useState([]);

  useEffect(() => {
    if (dashboard.length !== 0 && clusters.length !== 0) {
      const {
        match: { params },
      } = props;
      const { clusterId, nodeId } = params;
      const clusterItem = dashboard.find(item => item.cluster.id === clusterId);
      const { nodes } = clusterItem.cluster;
      const node = nodes.find(node => node.id === nodeId);
      const { charts } = node;
      setNode(node);
      setCharts(charts);
    }
  }, [clusters, dashboard, dispatch, props]);

  useEffect(() => {
    dispatch(getCluster());
    dispatch(getDashboard());
    const updateInterval = setInterval(() => {
      dispatch(updateCluster());
      dispatch(updateDashboard());
    }, 60000);
    const updateDashboardClusters = () => updateInterval;
    updateDashboardClusters();
    return () => {
      clearInterval(updateInterval);
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Status</h4>
          </div>
        </Col>
        <PageTitle
          breadCrumbItems={[
            { label: 'Status', path: '/dashboard/status' },
            { label: 'Node Details', path: '/dashboard/status', active: true },
          ]}
        />
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h5>{node.name}</h5>
              </CardTitle>
              {charts.map((item: Object, index: number) => {
                if (item.title === 'RADIUS ACCESS') {
                  return (
                    <>
                      <StackedAreaChart
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        title={item.title}
                        subtitle={item.subtitle}
                        series={item.series}
                        height={400}
                        xAxisLabelFormatter={xAxisLabelFormatter}
                      />
                      <Row>
                        <Col lg={12} md={12}>
                          <Table className="mt-2">
                            <thead>
                              <tr>
                                <th />
                                <th>last</th>
                                <th>min</th>
                                <th>avg</th>
                                <th>max</th>
                              </tr>
                            </thead>
                            <tbody>
                              {charts.length > 0 &&
                                charts.map(chart => {
                                  if (chart.title === 'RADIUS ACCESS') {
                                    return chart.series.map((serie, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <tr key={index}>
                                        <td>{serie.name}</td>
                                        <td>{serie.last}</td>
                                        <td>{serie.min}</td>
                                        <td>{serie.med}</td>
                                        <td>{serie.max}</td>
                                      </tr>
                                    ));
                                  }
                                  return null;
                                })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  );
                }
                if (item.title === 'RADIUS ACCOUNT') {
                  return (
                    <>
                      <StackedAreaChart
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        title={item.title}
                        subtitle={item.subtitle}
                        series={item.series}
                        height={400}
                        xAxisLabelFormatter={xAxisLabelFormatter}
                      />
                      <Row>
                        <Col lg={12} md={12}>
                          <Table className="mt-2">
                            <thead>
                              <tr>
                                <th />
                                <th>last</th>
                                <th>min</th>
                                <th>avg</th>
                                <th>max</th>
                              </tr>
                            </thead>
                            <tbody>
                              {charts.length > 0 &&
                                charts.map(chart => {
                                  if (chart.title === 'RADIUS ACCOUNT') {
                                    return chart.series.map((serie, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <tr key={index}>
                                        <td>{serie.name}</td>
                                        <td>{serie.last}</td>
                                        <td>{serie.min}</td>
                                        <td>{serie.med}</td>
                                        <td>{serie.max}</td>
                                      </tr>
                                    ));
                                  }
                                  return null;
                                })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  );
                }
                return (
                  <StackedAreaChart
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    series={item.series}
                    height={400}
                    xAxisLabelFormatter={xAxisLabelFormatter}
                  />
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NodeDetails;
