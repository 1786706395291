/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  FormText } from 'reactstrap';
import { addMacToTrace, deleteMacFromTrace } from '../../redux/actions';
import MaskedInput from 'react-text-mask';
import ErrorAlert from './ErrorAlert';
import UserRoleChecker from '../../components/UserRoleChecker';

const renderMacTable = (macs: Array<Object>, loading: Boolean) => {
  const dispatch = useDispatch();

  const handleMacDelete = (e, macId) => {
    e.preventDefault();
    if (loading) return;
    
    dispatch(deleteMacFromTrace({ macId }));
  }

  if (macs && macs.length > 0) {
    return (
      <Col>
        <Card>
          <CardBody>
            <div className="table-responsive-sm" style={{ maxHeight: '600px', overflow: 'auto'}}>
              <table className="table table-striped table-centered mb-0">
                  <thead>
                      <tr>
                          <th width="40%">MAC</th>
                          <th style={{textAlign: 'center'}}>Remove</th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                      macs.map(mac => {
                        return (
                          <tr key={mac.id}>
                            <td>{mac.macaddress}</td>
                            <td style={{textAlign: 'center'}}>
                              <button className="action-icon clear-background clear-border" onClick={e => handleMacDelete(e, mac.id)}>
                                <i className="mdi mdi-delete"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
  if (!macs || macs.length <= 0) {
    return (
      <Col>
        <Card>
          <CardBody>
            <p className="text-muted text-center font-18 mb-4">No MAC Adrresses being traced.</p>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return null;
}

const TracesModal = () => {
  const dispatch = useDispatch();
  const { macs, addingMac, deletingMacFromTrace, showSuccessAlert, error } = useSelector(state => state.OperationsTraces);
  const [inputs, setInputs] = useState({ newTraceMac: '' });
  const [modal, setModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const loading = addingMac || deletingMacFromTrace;
  
  const toggleModal = () => setModal(!modal);

  const handleTraceMacSubmit = e => {
    e.preventDefault();
    if (handleValidation()) {
      dispatch(addMacToTrace({macAddress: inputs.newTraceMac}));
      setInputs({newTraceMac: ''});
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    const validationErrors = {};

    if (!inputs.newTraceMac) {
      formIsValid = false;
      validationErrors.newTraceMac = 'Cannot be empty';
    }

    if (inputs.newTraceMac.includes("_")) {
      formIsValid = false;
      validationErrors.newTraceMac = 'Invalid format';
    }

    if (Array.isArray(macs) && macs.find(it => it.macaddress === inputs.newTraceMac)) {
      formIsValid = false;
      validationErrors.newTraceMac = 'This MAC is already on the MAC list';
    }

    setValidationErrors(validationErrors);
    return formIsValid;
  };

  const clearState = () => {
    setInputs({ newTraceMac: '' });
    setValidationErrors({});
  }

  return (
    <>
      <UserRoleChecker mustBeAdmin={true}>
        <Button color="outline-primary" onClick={toggleModal}>
          Manage
        </Button>
      </UserRoleChecker>
      <Modal isOpen={modal} toggle={toggleModal} style={{}} onClosed={clearState}>
        <ModalHeader toggle={toggleModal}>Manage Traces</ModalHeader>
        <ModalBody>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Form inline onSubmit={e => handleTraceMacSubmit(e)}>
                    <Col md={12} style={{ marginTop: '15px' }}>
                      <FormGroup row>
                        <Label for="newTraceMac" className="mr-2" md={1}>
                          MAC:
                        </Label>
                        <MaskedInput
                          mask={[
                            /[A-Za-z0-9)]/, /[A-Za-z0-9)]/, ':',
                            /[A-Za-z0-9)]/, /[A-Za-z0-9)]/, ':',
                            /[A-Za-z0-9)]/, /[A-Za-z0-9)]/, ':',
                            /[A-Za-z0-9)]/, /[A-Za-z0-9)]/, ':',
                            /[A-Za-z0-9)]/, /[A-Za-z0-9)]/, ':',
                            /[A-Za-z0-9)]/, /[A-Za-z0-9)]/]}
                          placeholder="XX:XX:XX:XX:XX:XX"
                          className="form-control"
                          value={inputs.newTraceMac}
                          onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value.toLowerCase() })}
                          name="newTraceMac"
                          id="newTraceMac"
                          style={{ width: '200px' }} />
                        <Button color="primary" className="ml-2" disabled={loading}>
                          {loading ? 'Please wait' : 'Trace'}
                        </Button>
                      </FormGroup>
                      <FormText color="danger" className="mb-2">
                        {validationErrors.newTraceMac}
                      </FormText>
                    </Col>
                  </Form>
                </CardBody>
              </Card>
              <ErrorAlert error={error}/>
              {
                showSuccessAlert && (
                  <Alert className="mt-2" color="success">
                    Success! To apply those changes you need to restart the cluster nodes in <Link className="text-primary" to="/operations/nodes-control"><span style={{ color: '#727cf5 !important;'}}>Nodes Control</span></Link>.
                  </Alert>
                )
              }
            </Col>
            {renderMacTable(macs, loading)}
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TracesModal;
