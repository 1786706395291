/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Col, Card, CardHeader, CardBody, Button, Badge } from 'reactstrap';
import { loadNodesControl,
  nodesControlStart,
  nodesControlStop } from '../../redux/actions';
import Spinner from '../../components/Spinner';
import ErrorAlert from './ErrorAlert';
import UserRoleChecker from '../../components/UserRoleChecker';

const renderCluster = (cluster, fetchingStartStop) => {
  const dispatch = useDispatch();
  
  const isRunning = (node) => {
    return node.status['freeradius'] === 'RUNNING' && node.status['httpInbound2'] === 'RUNNING'; 
  };

  const startNodeHandler = (nodeId) => {
    return () => dispatch(nodesControlStart({ nodeId }));
  }

  const stopNodeHandler = (nodeId) => {
    return () => dispatch(nodesControlStop({ nodeId }));
  }

  return (
    <Card>
      <CardHeader>
        <h5>{cluster.name}</h5>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th>Node</th>
              <th>Status</th>
              <UserRoleChecker mustBeAdmin={true}><th>Action</th></UserRoleChecker>
            </tr>
          </thead>
          <tbody>
            {
              cluster.nodes.map(node => {
                const running = isRunning(node);
                return (
                  <tr>
                    <td>{node.name}</td>
                    <td>
                      <h4><Badge color={running ? 'success' : 'secondary'}>{running ? 'RUNNING' : 'STOPPED'}</Badge></h4>
                    </td>
                    <UserRoleChecker mustBeAdmin={true}>
                      <td style={{maxWidth: '300px', minWidth: '300px'}}>
                        {
                          running && (
                            <Button onClick={stopNodeHandler(node.id)} color="secondary" disabled={fetchingStartStop}>
                              { fetchingStartStop ? 'Please wait' : 'Stop' }
                            </Button>
                          )
                        }
                        {
                          !running && (
                            <Button 
                              onClick={startNodeHandler(node.id)} 
                              color="success"
                              disabled={fetchingStartStop}>
                              { fetchingStartStop ? 'Please wait' : 'Start' }
                            </Button>
                          )
                        }
                      </td>
                    </UserRoleChecker>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

const renderLoading = (loading: Boolean) => {
  if (loading) {
    return (
      <Row>
        <Col
          lg={{ size: 12, offset: 5 }}
          md={{ size: 12, offset: 5 }}
          sm={{ size: 12, offset: 5 }}
          xs={{ size: 12, offset: 5 }}
          className="mt-5">
          <Spinner className="text-primary" type="grow" size="lg" />
        </Col>
      </Row>
    );
  }
  return null;
};

const NodesControl = () => {
  const dispatch = useDispatch();
  const { clusters, loading, fetchingStartStop, error } = useSelector(state => state.OperationsNodesControl);

  useEffect(() => {
    dispatch(loadNodesControl());
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Nodes Control</h4>
          </div>
        </Col>
      </Row>
      <ErrorAlert error={error}/>
      {
        renderLoading(loading)
      }
      {
        clusters && Array.isArray(clusters) && clusters.map(cluster  => renderCluster(cluster, fetchingStartStop))
      }
    </>
  );
};

export default NodesControl;
