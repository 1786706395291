// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Cluster from './cluster/reducers';
import Versions from './versions/reducers';
import Operations from './operations/reducers';
import OperationsSessions from './operations/reducers-sessions';
import OperationsTraces from './operations/reducers-traces';
import OperationsLogs from './operations/reducers-logs';
import OperationsNodesControl from './operations/reducers-nodes-control';

export default combineReducers({
  Auth,
  AppMenu,
  Layout,
  Cluster,
  Versions,
  Operations,
  OperationsSessions,
  OperationsTraces,
  OperationsLogs,
  OperationsNodesControl
});
