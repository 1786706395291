import React, { useState } from 'react';
import { Row, Col} from 'reactstrap';
import moment from 'moment';

const filteredLogParameters = ['@timestamp', 'message'];

const TraceItem = (props) => {
  const [state, setState] = useState({ show: false });
  
  const toggleDetails = e => {
    e.preventDefault();
    setState({ show: !state.show });
  }

  return (
    <div style={{marginBottom: '10px'}}>
      <Row>
        <Col md={2}>
          {
            props.data['@timestamp'] && (
              <div style={{textOverflow: 'ellipsis', wordWrap: 'break-word', overflow: 'hidden', maxHeight: '4.5em', lineHeight: '1.5em'}}>
                  {moment(props.data['@timestamp']).utc().format('LL')}<br/>
                  {moment(props.data['@timestamp']).utc().format('HH:mm:ss.SSS [(UTC)]')}<br/>
                  <span>{moment(props.data['@timestamp']).fromNow()}</span>
              </div>
            )
          }
        </Col>
        <Col md={8}>
          <div style={{textOverflow: 'ellipsis', wordWrap: 'break-word', overflow: 'hidden', maxHeight: '4.5em', lineHeight: '1.5em'}}>
            {
              Object
                .keys(props.data)
                .filter(key => !filteredLogParameters.includes(key) )
                .map(key => <span key={key}><b>{key}: </b>{props.data[key]} </span>)
            }
          </div>
          <button className="action-icon clear-background clear-border" onClick={toggleDetails}>
            <i className={`mdi ${state.show ? 'mdi mdi-arrow-collapse-up' : 'mdi-arrow-collapse-down'}`} />
          </button>
          <div className={`collapse ${state.show ? 'show' : ''}`}>
            <hr />
            {
              Object
                .keys(props.data)
                .map(key => (
                  <Row>
                    <Col md={3} style={{fontWeight: 'bold', textAlign: 'right'}}>{key}: </Col>
                    <Col md={9} style={{wordBreak: 'break-word'}}>{props.data[key]}</Col>
                  </Row>
                ))
            }
            <hr />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TraceItem;