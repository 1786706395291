// @flow
import {
  LOAD_TRACES_MAC_LIST,
  LOAD_TRACES_MAC_LIST_SUCCESS,
  LOAD_TRACES_MAC_LIST_FAILED,
  ADD_MAC_TO_TRACE,
  ADD_MAC_TO_TRACE_SUCCESS,
  ADD_MAC_TO_TRACE_FAILED,
  DELETE_MAC_FROM_TRACE,
  DELETE_MAC_FROM_TRACE_SUCCESS,
  DELETE_MAC_FROM_TRACE_FAILED,
  SEARCH_TRACES,
  SEARCH_TRACES_SUCCESS,
  SEARCH_TRACES_FAILED
} from './constants';

const INIT_STATE = {
  traces: null,
  loadingTraces: false,
  macs: null,
  loadingTracesMacList: false,
  addingMac: false,
  deletingMacFromTrace: false,
  showSuccessAlert: false
};

type TracesAction = { type: string, payload: {} | string };
type State = { traces?: [] | null, loadingTraces?: boolean, +value?: boolean };

const OperationsTraces = (state: State = INIT_STATE, action: TracesAction) => {
  switch (action.type) {
    case ADD_MAC_TO_TRACE:
      return { ...state, addingMac: true, showSuccessAlert: false };
    case ADD_MAC_TO_TRACE_SUCCESS:
      if (!state.macs) { state.macs = []; }
      state.macs.push(action.payload);
      
      return { ...state, addingMac: false, error: null, showSuccessAlert: true};
    case ADD_MAC_TO_TRACE_FAILED:
      return { ...state, error: action.payload, addingMac: false, showSuccessAlert: false };

    case LOAD_TRACES_MAC_LIST:
      return { ...state, loadingTracesMacList: true };
    case LOAD_TRACES_MAC_LIST_SUCCESS:
      return { ...state, macs: action.payload, loadingTracesMacList: false, error: null };
    case LOAD_TRACES_MAC_LIST_FAILED:
      return { ...state, error: action.payload, loadingTracesMacList: false };

    case DELETE_MAC_FROM_TRACE:
        return { ...state, deletingMacFromTrace: true, showSuccessAlert: false };
    case DELETE_MAC_FROM_TRACE_SUCCESS:
      if (action && action.params && action.params.macId) {
        state.macs = state.macs.filter(it => it.id !== action.params.macId);
      }
      return { ...state, deletingMacFromTrace: false, showSuccessAlert: true };
    case DELETE_MAC_FROM_TRACE_FAILED:
      return { ...state, error: action.payload, deletingMacFromTrace: false, showSuccessAlert: false };

    case SEARCH_TRACES:
      return { ...state, traces: null, loadingTraces: true };
    case SEARCH_TRACES_SUCCESS:
      return { ...state, traces: action.payload, loadingTraces: false, error: null };
    case SEARCH_TRACES_FAILED:
      return { ...state, error: action.payload, loadingTraces: false };
    
    default:
      return { ...state };
  }
};

export default OperationsTraces;
