// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { LOGIN_USER, LOGOUT_USER } from './constants';

import { loginUserSuccess, loginUserFailed } from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
  const cookies = new Cookies();
  if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
  else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload }) {
  try {
    /**
     * By now, there is only one apolo role for MobiAAA.
     * It was decided that users without this role should be considered unauthorized.
     * This is implemented bellow. If this logic get more complex in the future, please, 
     * abstract and implement it decoupled from this piece.
     * please see auth/saga.js and AuthCallback.js
     */

    payload.roles = {};
    payload.roles.arr = rolesAsArray(payload['https://nds.oi.com/roles']);

    if (payload.roles.arr) {
      payload.roles.isMobiAaaAdmin = !!payload.roles.arr.find(it => it === 'MOBIAAA_ADMIN');
      payload.roles.isMobiAaaViewer = !!payload.roles.arr.find(it => it === 'MOBIAAA_VIEWER');
      payload.roles.hasMobiAaaRole = payload.roles.isMobiAaaAdmin || payload.roles.isMobiAaaViewer;
    }

    yield put(loginUserSuccess(payload));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(loginUserFailed(message));
    setSession(null);
  }
}

function rolesAsArray(roles) {
  if (typeof roles === 'string') {
    return [roles];
  }

  if (Array.isArray(roles)) {
    return roles;
  }

  return null;
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push('/');
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

function* authSaga(): any {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}

export default authSaga;
