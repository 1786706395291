// @flow
import {
  SEARCH_SESSION,
  SEARCH_SESSION_SUCCESS,
  SEARCH_SESSION_FAILED
} from './constants';

const INIT_STATE = {
  session: null,
  loadingSession: false,
};

type SessionsAction = { type: string, payload: {} | string };
type State = { session?: {} | null, loadingSession?: boolean, +value?: boolean };

const OperationsSessions = (state: State = INIT_STATE, action: SessionsAction) => {
  switch (action.type) {
    case SEARCH_SESSION:
      return { ...state, loadingSession: true };
    case SEARCH_SESSION_SUCCESS:
      return { ...state, session: action.payload, loadingSession: false, error: null };
    case SEARCH_SESSION_FAILED:
      return { ...state, error: action.payload, loadingSession: false };
    
    default:
      return { ...state };
  }
};

export default OperationsSessions;
