/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Badge,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import uuidv1 from 'uuid/v1';
import { addCluster, deleteCluster } from '../../redux/actions';
import UserRoleChecker from '../../components/UserRoleChecker';

const Nodes = props => {
  const dispatch = useDispatch();
  const { isEditMode } = useSelector(state => state.Cluster);
  const { data } = useSelector(state => state.Cluster.clusters);
  const { clusters } = useSelector(state => state.Cluster);
  const [inputs, setInputs] = useState({ name: '', ip: '', isHealth: true, isPrimary: false, location: '' });
  const [modal, setModal] = useState(false);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const {
        match: { params },
      } = props;
      const { clusterId } = params;
      const { cluster } = data.find(cluster => cluster.cluster.id === clusterId);
      setNodes(cluster.nodes);
    }
  }, [data, props]);

  const toggle = async () => {
    await setModal(!modal);
    if (modal) {
      setInputs({ name: '', ip: '', isHealth: true, isPrimary: false, location: '' });
    }
  };

  const saveCluster = clusters => {
    dispatch(addCluster(clusters));
    setInputs({});
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const {
      match: { params },
    } = props;
    const { clusterId } = params;
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId);
    if (inputs.id) {
      const nodesEdited = cluster.cluster.nodes.map(node => {
        if (node.id === inputs.id) {
          node = inputs;
        }
        return node;
      });
      cluster.cluster.nodes = nodesEdited;
    } else {
      cluster.cluster.nodes.push({ ...inputs, id: uuidv1() });
    }
    saveCluster(clustersClone);
    return setNodes(cluster.cluster.nodes);
  };

  const handleDelete = node => {
    const {
      match: { params },
    } = props;
    const { clusterId } = params;
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId);
    const index = cluster.cluster.nodes.indexOf(node);
    if (index > -1) {
      cluster.cluster.nodes.splice(index, 1);
      setNodes(cluster.cluster.nodes);
    }
    dispatch(deleteCluster(clustersClone));
  };

  const handleEdit = async cluster => {
    await setInputs(cluster);
    console.log(inputs);
    toggle();
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Node</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Form>
                <FormGroup>
                  <Label for="ip">Ip</Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    name="ip"
                    className="mb-2"
                    id="ip"
                    value={inputs.ip}
                  />
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    name="name"
                    className="mb-2"
                    id="name"
                    value={inputs.name}
                  />
                  <Label for="isHealth">Is Health</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value === 'true' })}
                    name="isHealth"
                    className="mb-2"
                    id="isHealth"
                    value={inputs.isHealth}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Input>
                  <Label for="isPrimary">Is Primary</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value === 'true' })}
                    name="isPrimary"
                    className="mb-2"
                    id="isPrimary"
                    value={inputs.isPrimary}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Input>
                  <Label for="location">Location</Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    name="location"
                    className="mb-2"
                    id="location"
                    value={inputs.location}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={!inputs.ip || !inputs.name || !inputs.location}
            onClick={e => handleSubmit(e)}
            color="primary">
            Save Node
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Nodes</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col lg={10} sm={10} md={10}>
                    <h4>The list of Nodes</h4>
                  </Col>
                  <UserRoleChecker mustBeAdmin={true}>
                    <Col lg={2} sm={2} md={2}>
                      <Button disabled={!isEditMode} onClick={toggle} color="primary">
                        Add New Node
                      </Button>
                    </Col>
                  </UserRoleChecker>
                </Row>
              </CardTitle>
              <Row>
                <Col lg={12} md={12}>
                  <Table striped>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Ip</th>
                        <th>Name</th>
                        <th>Is Health</th>
                        <th>Location</th>
                        <th>Is Primary</th>
                        <th className={classNames({ hide: !isEditMode })}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nodes.map(node => (
                        <tr style={{ textAlign: 'center' }}>
                          <td>{node.ip}</td>
                          <td>{node.name}</td>
                          <td>
                            <Badge color={node.isHealth ? 'success' : 'danger'}>{node.isHealth ? 'Yes' : 'No'}</Badge>
                          </td>
                          <td>{node.location}</td>
                          <td>
                            <Badge color={node.isPrimary ? 'success' : 'danger'}>{node.isPrimary ? 'Yes' : 'No'}</Badge>
                          </td>
                          <td className={classNames({ hide: !isEditMode }, 'table-action')}>
                            <button className="action-icon clear-background clear-border">
                              <i
                                role="button"
                                onClick={() => handleEdit(node)}
                                onKeyPress={() => handleEdit(node)}
                                className="mdi mdi-pencil"
                              />
                            </button>
                            <button className="action-icon clear-background clear-border">
                              <i
                                role="button"
                                onClick={() => handleDelete(node)}
                                onKeyPress={() => handleDelete(node)}
                                className="mdi mdi-delete"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Nodes;
