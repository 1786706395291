// @flow
import {
  DEPLOY_VERSION,
  DEPLOY_VERSION_SUCCESS,
  DEPLOY_VERSION_FAILED,
  GET_VERSIONS,
  GET_VERSIONS_SUCCESS,
  GET_VERSIONS_FAILED,
  GET_APPLIED_VERSIONS_SUCCESS,
  GET_APPLIED_VERSIONS_FAILED
} from './constants';

const INIT_STATE = {
  versions: [],
  loadingVersions: false,
  loadingDeploy: false,
};

type VersionsAction = { type: string, payload: {} | string };
type State = { clusters?: [] | null, loading?: boolean, +value?: boolean };

const Versions = (state: State = INIT_STATE, action: VersionsAction) => {
  switch (action.type) {
    case GET_VERSIONS:
      return { ...state, loadingVersions: true };
    case GET_VERSIONS_SUCCESS:
      return { 
        ...state,
        versions: action.payload, 
        loadingVersions: false, 
        error: null,
        nodesVersionMap: mapNodeVersions(action.payload)};
    case GET_VERSIONS_FAILED:
      return { ...state, error: action.payload, loadingVersions: false };
    case DEPLOY_VERSION:
      return { ...state, loadingDeploy: true };
    case DEPLOY_VERSION_SUCCESS:
      return { ...state, loadingDeploy: false, showModal: false };
    case DEPLOY_VERSION_FAILED:
      return { ...state, loadingDeploy: false, error: action.payload };
    case GET_APPLIED_VERSIONS_SUCCESS:
      return { ...state, appliedVersions: action.payload };
    case GET_APPLIED_VERSIONS_FAILED:
      return { ...state, error: action.payload, appliedVersions: null };
    
    default:
      return { ...state };
  }
};

function mapNodeVersions(versions) {
  // FIXME: sorry about this :)
  // maybe server could serve data in a better format 
  if (!versions || !Array.isArray(versions) || versions.length < 1) {
    return null;
  }
  
  const res = {};

  versions.forEach(version => {
    const nodeIdArr = version.deployedTo;
    if (versions && Array.isArray(versions) && versions.length >= 1) {
      nodeIdArr.forEach(nodeId => {res[nodeId] = version});
    }
  })

  return res;
}

export default Versions;
