// @flow
import {
  LOAD_TRACES_MAC_LIST,
  LOAD_TRACES_MAC_LIST_SUCCESS,
  LOAD_TRACES_MAC_LIST_FAILED,
  ADD_MAC_TO_TRACE,
  ADD_MAC_TO_TRACE_SUCCESS,
  ADD_MAC_TO_TRACE_FAILED,
  DELETE_MAC_FROM_TRACE,
  DELETE_MAC_FROM_TRACE_SUCCESS,
  DELETE_MAC_FROM_TRACE_FAILED,
  SEARCH_TRACES,
  SEARCH_TRACES_SUCCESS,
  SEARCH_TRACES_FAILED
} from './constants';

type TracesAction = { type: string, payload: {} | string | Array<Object> };

export const loadTracesMacList = (params: Object): TracesAction => ({
  type: LOAD_TRACES_MAC_LIST,
  params,
});

export const loadTracesMacListSuccess = (payload: Array<Object>): TracesAction => ({
  type: LOAD_TRACES_MAC_LIST_SUCCESS,
  payload
});

export const loadTracesMacListFailed = (error: Object): TracesAction => ({
  type: LOAD_TRACES_MAC_LIST_FAILED,
  payload: error
});

export const addMacToTrace = (params: Object): TracesAction => ({
  type: ADD_MAC_TO_TRACE,
  params,
});

export const addMacToTraceSuccess = (payload: Object): TracesAction => ({
  type: ADD_MAC_TO_TRACE_SUCCESS,
  payload
});

export const addMacToTraceFailed = (error: Object): TracesAction => ({
  type: ADD_MAC_TO_TRACE_FAILED,
  payload: error
});

export const deleteMacFromTrace = (params: Object): TracesAction => ({
  type: DELETE_MAC_FROM_TRACE,
  params,
});

export const deleteMacFromTraceSuccess = (params: Object): TracesAction => ({
  type: DELETE_MAC_FROM_TRACE_SUCCESS,
  params,
});

export const deleteMacFromTraceFailed = (error: Object): TracesAction => ({
  type: DELETE_MAC_FROM_TRACE_FAILED,
  payload: error
});

export const searchTraces = (params: Object): TracesAction => ({
  type: SEARCH_TRACES,
  payload: {},
  params,
});

export const searchTracesSuccess = (traces: Array<Object>): TracesAction => ({
  type: SEARCH_TRACES_SUCCESS,
  payload: traces,
});


export const searchTracesFailed = (error: string): TracesAction => ({
  type: SEARCH_TRACES_FAILED,
  payload: error,
});
