// @flow
import {
  LOAD_NODES_CONTROL,
  LOAD_NODES_CONTROL_SUCCESS,
  LOAD_NODES_CONTROL_FAILED,
  NODES_CONTROL_START,
  NODES_CONTROL_START_FAILED,
  NODES_CONTROL_STOP,
  NODES_CONTROL_STOP_FAILED,
} from './constants';

type NodesControlAction = { type: string, payload: {} | string | Array<Object> };

export const loadNodesControl = (params: Object): NodesControlAction => ({
  type: LOAD_NODES_CONTROL,
  params,
});

export const loadNodesControlSuccess = (clusters: Object): NodesControlAction => ({
  type: LOAD_NODES_CONTROL_SUCCESS,
  payload: clusters
});

export const loadNodesControlFailed = (error: string): NodesControlAction => ({
  type: LOAD_NODES_CONTROL_FAILED,
  payload: error
});

export const nodesControlStart = (params: Object): NodesControlAction => ({
  type: NODES_CONTROL_START,
  params,
});

export const nodesControlStartFailed = (error: string): NodesControlAction => ({
  type: NODES_CONTROL_START_FAILED,
  payload: error
});

export const nodesControlStop = (params: Object): NodesControlAction => ({
  type: NODES_CONTROL_STOP,
  params,
});

export const nodesControlStopFailed = (error: string): NodesControlAction => ({
  type: NODES_CONTROL_STOP_FAILED,
  payload: error
});
