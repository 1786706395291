// @flow
import React from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';

const About = () => {
  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">About</h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form inline>
              <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="license" className="mr-sm-2" style={{ width: '164px', textAlign: 'right', display: 'block' }}>
                      License:
                    </Label>
                    <Input
                      style={{ width: '340px' }}
                      type="text"
                      name="license"
                      disabled
                      id="license"
                      value="8DD7CB43-9548-4DF1-ACA6-BFCB39F0C0F3" />
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="license" className="mr-sm-2" style={{ width: '164px', textAlign: 'right', display: 'block' }}>
                      MobiAAA Version:
                    </Label>
                    <Input
                      style={{ width: '100px' }}
                      type="text"
                      name="version"
                      disabled
                      id="version"
                      value="1.0" />
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="license" className="mr-sm-2" style={{ width: '164px', textAlign: 'right', display: 'block' }}>
                      MobiAAA Radius Version:
                    </Label>
                    <Input
                      style={{ width: '100px' }}
                      type="text"
                      name="r-version"
                      disabled
                      id="r-version"
                      value="1.0" />
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="license" className="mr-sm-2" style={{ width: '164px', textAlign: 'right', display: 'block' }}>
                      MobiAAA UI Version:
                    </Label>
                    <Input
                      style={{ width: '100px' }}
                      type="text"
                      name="ui-version"
                      disabled
                      id="ui-version"
                      value="1.0" />
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default About;
