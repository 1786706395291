/* eslint-disable radix */
/* eslint-disable no-script-url */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import uuidv1 from 'uuid/v1';
import classNames from 'classnames';
import { addNasClient, deleteNasClient } from '../../redux/actions';
import UserRoleChecker from '../../components/UserRoleChecker';

const RadiusServer = props => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.Cluster.clusters);
  const { isEditMode } = useSelector(state => state.Cluster);
  const { clusters } = useSelector(state => state.Cluster);
  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({ enabled: 0 });
  const [serverClient, setServerClient] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const {
        match: { params },
      } = props;
      const { clusterId, radiusGroupId } = params;
      const { cluster } = data.find(cluster => cluster.cluster.id === clusterId);
      const radiusGroup = cluster.serverGroup.find(group => group.id === radiusGroupId);
      setServerClient(radiusGroup.serverClient);
      // setCluster(cluster);
    }
  }, [data, props]);

  const toggle = async (isNew) => {
    if (isNew) {
      setInputs({ type: 'auth+acct', enabled: 0, statusCheck: 'none', reviveInternal: 60, numAnswersToAlive: 3 }); 
    }

    await setModal(!modal);
    
    if (modal) {
      setInputs({ enabled: 0 });
    }
  };

  const handleEdit = client => {
    setInputs(client);
    toggle();
  };

  const handleDelete = client => {
    const {
      match: { params },
    } = props;
    const { clusterId, radiusGroupId } = params;
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId);
    const radiusGroup = cluster.cluster.serverGroup.find(group => group.id === radiusGroupId);
    const index = radiusGroup.serverClient.indexOf(client);
    if (index > -1) {
      // clients.splice(index, 1);
      radiusGroup.serverClient.splice(index, 1);
      setServerClient(radiusGroup.serverClient);
    }
    dispatch(deleteNasClient(clustersClone));
  };

  const saveServerGroup = clustersClone => {
    dispatch(addNasClient(clustersClone));
    setInputs({});
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const {
      match: { params },
    } = props;
    const { clusterId, radiusGroupId } = params;
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId);
    const radiusGroup = cluster.cluster.serverGroup.find(group => group.id === radiusGroupId);

    if (inputs.id) {
      const clientsEdited = radiusGroup.serverClient.map(client => {
        if (client.id === inputs.id) {
          client = inputs;
        }
        return client;
      });
      radiusGroup.serverClient = clientsEdited;
    } else if (radiusGroup.serverClient) {
      radiusGroup.serverClient.push({ ...inputs, id: uuidv1() });
    } else {
      radiusGroup.serverClient = [inputs];
    }
    saveServerGroup(clustersClone);
    return setServerClient(radiusGroup.serverClient);
  };

  const isEmpty = (val) => { return val === null || val === undefined || val === '' };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Server Client</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Form>
                <FormGroup>
                  <Row>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="name">Name</Label>
                      <Input
                        type="text"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="name"
                        id="name"
                        value={inputs.name}
                      />
                    </Col>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="type">Type</Label>
                      <Input
                        type="text"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="type"
                        id="type"
                        value={inputs.type}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="secret">Secret</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="secret"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="secret"
                        value={inputs.secret}
                      />
                    </Col>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="enabled">Enabled</Label>
                      <Input
                        type="select"
                        className="mb-2"
                        name="enabled"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="enabled"
                        value={inputs.enabled}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="acctport">Acct Port</Label>
                      <Input
                        type="number"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="acctport"
                        id="acctport"
                        value={inputs.acctport}
                      />
                    </Col>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="authport">Auth Port</Label>
                      <Input
                        type="number"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="authport"
                        id="authport"
                        value={inputs.authport}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="priority">Priority</Label>
                      <Input
                        type="number"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="priority"
                        id="priority"
                        value={inputs.priority}
                      />
                    </Col>
                    <Col sm={6} md={6} xs={6} lg={6}>
                      <Label for="ipaddress">Ip Address</Label>
                      <Input
                        type="text"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="ipaddress"
                        id="ipaddress"
                        value={inputs.ipaddress}
                      />
                    </Col>
                  </Row>
                  <Label for="statusCheck">Status Check</Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="statusCheck"
                    id="statusCheck"
                    value={inputs.statusCheck}
                  />
                  <Label for="reviveInternal">Revive Internal</Label>
                  <Input
                    type="number"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="reviveInternal"
                    id="reviveInternal"
                    value={inputs.reviveInternal}
                  />
                  <Label for="numAnswersToAlive">Answers To Alive</Label>
                  <Input
                    type="number"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="numAnswersToAlive"
                    id="numAnswersToAlive"
                    value={inputs.numAnswersToAlive}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={
              isEmpty(inputs.name) ||
              isEmpty(inputs.type) ||
              isEmpty(inputs.secret) ||
              isEmpty(inputs.enabled) ||
              isEmpty(inputs.acctport) ||
              isEmpty(inputs.authport) ||
              isEmpty(inputs.priority) ||
              isEmpty(inputs.ipaddress) ||
              isEmpty(inputs.statusCheck) ||
              isEmpty(inputs.reviveInternal) ||
              isEmpty(inputs.numAnswersToAlive)
            }
            onClick={e => handleSubmit(e)}
            color="primary">
            Save Server Client
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Radius Server Client</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col lg={10} sm={10} md={10}>
                    <h4>The list of Radius Server Client</h4>
                  </Col>
                  <UserRoleChecker mustBeAdmin={true}>
                    <Col lg={2} sm={2} md={2}>
                      <Button disabled={!isEditMode} color="primary" onClick={() => { toggle(true) }}>
                        Add New Server Client
                      </Button>
                    </Col>
                  </UserRoleChecker>
                </Row>
              </CardTitle>
              <Row>
                <Col lg={12} md={12}>
                  {serverClient.length === 0 && (
                    <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
                  )}
                  {serverClient.length > 0 && (
                    <Table responsive striped>
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Secret</th>
                          <th>Enabled</th>
                          <th>Acct Port</th>
                          <th>Auth Port</th>
                          <th>Priority</th>
                          <th>Ip Address</th>
                          <th>Status Check</th>
                          <th>Revive Internal</th>
                          <th>Answers To Alive</th>
                          <th className={classNames({ hide: !isEditMode })}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serverClient.map(client => (
                          <tr style={{ textAlign: 'center' }}>
                            <td>{client.name}</td>
                            <td>{client.type}</td>
                            <td>{client.secret}</td>
                            <td>
                              <Badge color={parseInt(client.enabled) ? 'success' : 'danger'}>
                                {parseInt(client.enabled) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>{client.acctport}</td>
                            <td>{client.authport}</td>
                            <td>{client.priority}</td>
                            <td>{client.ipaddress}</td>
                            <td>{client.statusCheck}</td>
                            <td>{client.reviveInternal}</td>
                            <td>{client.numAnswersToAlive}</td>
                            <td className={classNames({ hide: !isEditMode }, 'table-action')}>
                              <a href="javascript: void(0);" className="action-icon">
                                <i
                                  role="button"
                                  onClick={() => handleEdit(client)}
                                  onKeyPress={() => handleEdit(client)}
                                  className="mdi mdi-pencil"
                                />
                              </a>
                              <a href="javascript: void(0);" className="action-icon">
                                <i
                                  role="button"
                                  onClick={() => handleDelete(client)}
                                  onKeyPress={() => handleDelete(client)}
                                  className="mdi mdi-delete"
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RadiusServer;
