// @flow
import {
  LOAD_NODES_CONTROL,
  LOAD_NODES_CONTROL_SUCCESS,
  LOAD_NODES_CONTROL_FAILED,
  NODES_CONTROL_START,
  NODES_CONTROL_START_FAILED,
  NODES_CONTROL_STOP,
  NODES_CONTROL_STOP_FAILED,
} from './constants';

const INIT_STATE = {
  clusters: null,
  loading: false,
  fetchingStartStop: false
};

type NodesControlAction = { type: string, payload: {} | string };
type State = { clusters?: [] | null, loading?: boolean, +value?: boolean };

const OperationsNodesControl = (state: State = INIT_STATE, action: NodesControlAction) => {
  switch (action.type) {
    case LOAD_NODES_CONTROL:
      return { ...state, loading: true, fetchingStartStop: false, clusters: null };
    case LOAD_NODES_CONTROL_SUCCESS:
      return { ...state, clusters: action.payload, loading: false, error: null };
    case LOAD_NODES_CONTROL_FAILED:
      return { ...state, error: action.payload, loading: false };

    case NODES_CONTROL_START:
    case NODES_CONTROL_STOP:
      return { ...state, fetchingStartStop: true };
    case NODES_CONTROL_START_FAILED:
    case NODES_CONTROL_STOP_FAILED:
      return { ...state, error: action.payload, fetchingStartStop: false };
    
    default:
      return { ...state };
  }
};

export default OperationsNodesControl;
