/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, FormText, Table } from 'reactstrap';
import { searchLogs } from '../../redux/actions';
import Spinner from '../../components/Spinner';
import HyperDatepicker from '../../components/Datepicker';
import ErrorAlert from './ErrorAlert';

const renderLogs = (logs: Object, loadingLogs: Boolean) => {
  if (logs && logs.length > 0) {
    return (
      <Col>
        <Card>
          <CardBody>
            <p className="text-muted font-14 mb-4">Your search returned the following result.</p>
            <Table striped responsive>
              <thead>
                  <tr>
                      <th>Time</th>
                      <th>Radius Type</th>
                      <th>Virtual Server</th>
                      <th>Message</th>
                  </tr>
              </thead>
              <tbody>
                {
                  logs.map(log => {
                    return (
                      <tr>
                        <td>{log['@timestamp']}</td>
                        <td>{log.radiustype}</td>
                        <td>{log.virtualserver}</td>
                        <td>{log.message}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
  if ((!logs || logs.length === 0) && !loadingLogs) {
    return (
      <Col>
        <Card>
          <CardBody>
            <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return null;
};

const renderLoading = (loadingLogs: Boolean) => {
  if (loadingLogs) {
    return (
      <Col
        lg={{ size: 12, offset: 5 }}
        md={{ size: 12, offset: 5 }}
        sm={{ size: 12, offset: 5 }}
        xs={{ size: 12, offset: 5 }}
        className="mt-5">
        <Spinner className="text-primary" type="grow" size="lg" />
      </Col>
    );
  }
  return null;
};

const Logs = () => {
  const dispatch = useDispatch();
  const { logs, loadingLogs, error } = useSelector(state => state.OperationsLogs);
  const [inputs, setInputs] = useState({ radiusType: null, message: '', dateFrom: null, dateTo: null });
  const [validationErrors, setValidationErrors] = useState({});

  const handleSubmit = e => {
    e.preventDefault();
    if (handleValidation()) {
      const params = {...inputs};
      dispatch(searchLogs(params));
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    const validationErrors = {};

    if (!inputs.message) {
      formIsValid = false;
      validationErrors.message = 'Cannot be empty';
    }

    setValidationErrors(validationErrors);
    return formIsValid;
  };

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Logs</h4>
          </div>
        </Col>
      </Row>
      <ErrorAlert error={error}/>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form inline onSubmit={e => handleSubmit(e)} autocomplete="off">
              <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="nodeId" className="mr-2" md={1}>
                      Radius Type:
                    </Label>
                    <Input 
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      type="select" 
                      className="form-control" 
                      name="radiusType" 
                      id="radiusType">
                         <option value="">All</option>
                         <option value="auth">Auth</option>
                         <option value="acct">Acct</option>
                         <option value="coa">Coa</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="parameter" className="mr-2" md={1}>
                      Message:
                    </Label>
                    <Input
                      type="text"
                      name="message"
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      className="form-control"
                      id="message"
                      style={{width: '547px'}}
                    />
                    <FormText color="danger" className="mb-2">
                      {validationErrors.message}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="dateFrom" className="mr-2" md={1}>
                      From:
                    </Label>
                    <HyperDatepicker
                      id="dateFrom"
                      hideAddon
                      dateFormat="yyyy-MM-dd"
                      selected={inputs.dateFrom}
                      onChange={date => setInputs({ ...inputs, dateFrom: date })}
                    />
                    <Label for="dateTo" className="mr-2 text-left" md={1}>
                      To:
                    </Label>
                    <HyperDatepicker
                      id="dateTo"
                      hideAddon
                      dateFormat="yyyy-MM-dd"
                      selected={inputs.dateTo}
                      onChange={date => setInputs({ ...inputs, dateTo: date })}
                    />
                    <Button color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {renderLogs(logs, loadingLogs)}
        {renderLoading(loadingLogs)}
      </Row>
    </>
  );
};

export default Logs;
