/* eslint-disable no-script-url */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow
import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import uuidv1 from 'uuid/v1';
import classNames from 'classnames';
import { addNasClient, deleteNasClient } from '../../redux/actions';
import UserRoleChecker from '../../components/UserRoleChecker';

const Clients = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.Cluster.clusters);
  const { clusters } = useSelector(state => state.Cluster);
  const { isEditMode } = useSelector(state => state.Cluster);
  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({ server: 'mcare-nas-8950', macAuthEnabled: 1, internal: 1 });
  const [newOrEditMode, setNewOrEditMode] = useState(null);
  const [clusterId, setClusterId] = useState({ cluster: data[0].cluster.id });
  const [clients, setClients] = useState(data[0].cluster.clients);

  const toggle = async (mode) => {
    if (mode === 'NEW') {
      setInputs({ 
        server: 'mcare-nas-8950',
        internal: 1,
        ports: '3799',
        type: 'other',
        ipv6Pool: 'ipv6_testes',
        macAuthEnabled: 0,
        subscriberFilter: 'Ingr-v4:200,Ingr-v6:200',
        portalUrl: 'https://www7.wifioi.com.br/r/redir?acctSessionId=',
        forwardPolicy: 'in:REDIRECT_ACL_SPONSORED' });
    }

    await setNewOrEditMode(mode)
    await setModal(!modal);
    
    if (modal) {
      setInputs({ server: 'mcare-nas-8950', macAuthEnabled: 1, internal: 1 });
    }
  };

  const handleEdit = client => {
    setInputs(client);
    toggle('EDIT');
  };

  const handleDelete = client => {
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId.cluster);
    const index = clients.indexOf(client);
    if (index > -1) {
      // clients.splice(index, 1);
      cluster.cluster.clients.splice(index, 1);
      setClients(cluster.cluster.clients);
    }
    dispatch(deleteNasClient(clustersClone));
  };

  const handleSearch = e => {
    e.preventDefault();
    const cluster = data.find(cluster => cluster.cluster.id === clusterId.cluster);
    if (cluster) {
      return setClients(cluster.cluster.clients || []);
    }
    return setClients([]);
  };

  const saveNasClient = clustersClone => {
    dispatch(addNasClient(clustersClone));
    setInputs({});
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId.cluster);

    if (inputs.id) {
      const clientsEdited = cluster.cluster.clients.map(client => {
        if (client.id === inputs.id) {
          client = { ...inputs, macAuthEnabled: parseInt(inputs.macAuthEnabled), internal: parseInt(inputs.internal) };
        }
        return client;
      });
      cluster.cluster.clients = clientsEdited;
    } else if (cluster.cluster.clients) {
      cluster.cluster.clients.push({
        ...inputs,
        id: uuidv1(),
        macAuthEnabled: parseInt(inputs.macAuthEnabled),
        internal: parseInt(inputs.internal),
      });
    } else {
      cluster.cluster.clients = [
        { ...inputs, macAuthEnabled: parseInt(inputs.macAuthEnabled), internal: parseInt(inputs.internal) },
      ];
    }
    saveNasClient(clustersClone);
    return setClients(cluster.cluster.clients);
  };

  const isEmpty = (val) => { return val === null || val === undefined || val === '' };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Nas Client</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Form>
                <FormGroup>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="nasname">Nas Name</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        name="nasname"
                        id="nasname"
                        value={inputs.nasname}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="shortname">Name</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="shortname"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="shortname"
                        value={inputs.shortname}
                        disabled={newOrEditMode==='EDIT'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="secret">Secret</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="secret"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="secret"
                        value={inputs.secret}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="ports">Ports</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="ports"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="ports"
                        value={inputs.ports}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="server">Virtual Server</Label>
                      <Input
                        type="select"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        className="form-control mb-2"
                        name="server"
                        id="server"
                        defaultValue="mcare-nas-8950">
                        <option value="mcare-nas-8950">mcare-nas-8950</option>
                        <option value="mcare-nas-vex">mcare-nas-vex</option>
                        <option value="mcare-nas-fon">mcare-nas-fon</option>
                        <option value="mcare-nas-meraki">mcare-nas-meraki</option>
                        <option value="mcare-nas-ruckus">mcare-nas-ruckus</option>
                        <option value="mcare-nas-aruba">mcare-nas-aruba</option>
                        <option value="mcare-nas-zd">mcare-nas-zd</option>
                        <option value="mcare-nas-portal">mcare-nas-portal</option>
                      </Input>
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="description">Description</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="description"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="description"
                        value={inputs.description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="type">Type</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        name="type"
                        id="type"
                        value={inputs.type}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="internal">Internal</Label>
                      <Input
                        type="select"
                        className="mb-2"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        name="internal"
                        id="internal"
                        value={inputs.internal}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="ipv6Pool">Ipv6 Pool</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="ipv6Pool"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="ipv6Pool"
                        value={inputs.ipv6Pool}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="coasecret">Coa Secret</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        name="coasecret"
                        id="coasecret"
                        value={inputs.coasecret}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="portalUrl">Portal Url</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        name="portalUrl"
                        id="portalUrl"
                        value={inputs.portalUrl}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="forwardPolicy">Forward Policy</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="forwardPolicy"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="forwardPolicy"
                        value={inputs.forwardPolicy}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="macAuthEnabled">Mac Auth Enabled</Label>
                      <Input
                        type="select"
                        className="mb-2"
                        onChange={e => {console.log(inputs); return setInputs({ ...inputs, [e.target.name]: e.target.value })}}
                        name="macAuthEnabled"
                        id="macAuthEnabled"
                        value={inputs.macAuthEnabled}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Input>
                    </Col>
                    <Col md={6} sm={6} xs={6} lg={6}>
                      <Label for="subscriberFilter">Subscriber Filter</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        name="subscriberFilter"
                        onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                        id="subscriberFilter"
                        value={inputs.subscriberFilter}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={
              isEmpty(inputs.ports) ||
              isEmpty(inputs.nasname) ||
              isEmpty(inputs.shortname) ||
              isEmpty(inputs.server) ||
              isEmpty(inputs.secret) ||
              isEmpty(inputs.description) ||
              isEmpty(inputs.type) ||
              isEmpty(inputs.internal) ||
              isEmpty(inputs.ipv6Pool) ||
              isEmpty(inputs.coasecret) ||
              isEmpty(inputs.portalUrl) ||
              isEmpty(inputs.forwardPolicy) ||
              isEmpty(inputs.macAuthEnabled) ||
              isEmpty(inputs.subscriberFilter)
            }
            onClick={e => handleSubmit(e)}
            color="primary">
            Save Nas Client
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Nas Clients</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form onSubmit={e => e.preventDefault()} inline>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="action" className="mr-2" md={1}>
                      Cluster:
                    </Label>
                    <Input
                      type="select"
                      style={{ minWidth: '200px' }}
                      onChange={e => setClusterId({ ...clusterId, [e.target.name]: e.target.value })}
                      className="form-control"
                      name="cluster"
                      id="cluster">
                      {data.map(cluster => (
                        <option value={cluster.cluster.id}>{cluster.cluster.name}</option>
                      ))}
                    </Input>
                    <Button onClick={e => handleSearch(e)} color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col lg={10} sm={10} md={10}>
                    <h4>The list of Nas Clients</h4>
                  </Col>
                  <UserRoleChecker mustBeAdmin={true}>
                    <Col lg={2} sm={2} md={2}>
                      <Button disabled={!isEditMode} color="primary" onClick={() => {toggle('NEW')}}>
                        Add New Nas Client
                      </Button>
                    </Col>
                  </UserRoleChecker>
                </Row>
              </CardTitle>
              <Row>
                <Col lg={12} md={12}>
                  {clients.length === 0 && (
                    <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
                  )}
                  {clients.length > 0 && (
                    <Table striped responsive>
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th>Nas Name</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Virtual Server</th>
                          <th>Type</th>
                          <th>Internal</th>
                          <th>Mac Auth Enabled</th>
                          <th className={classNames({ hide: !isEditMode })}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map(client => (
                          <tr style={{ textAlign: 'center' }}>
                            <td>{client.nasname}</td>
                            <td>{client.shortname}</td>
                            <td>{client.description || '---'}</td>
                            <td>{client.server}</td>
                            <td>{client.type}</td>
                            <td>
                              <Badge color={parseInt(client.internal) ? 'success' : 'danger'}>
                                {parseInt(client.internal) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge color={parseInt(client.macAuthEnabled) ? 'success' : 'danger'}>
                                {parseInt(client.macAuthEnabled) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td className={classNames({ hide: !isEditMode }, 'table-action')}>
                              <a href="javascript: void(0);" className="action-icon">
                                <i
                                  role="button"
                                  onClick={() => handleEdit(client)}
                                  onKeyPress={() => handleEdit(client)}
                                  className="mdi mdi-pencil"
                                />
                              </a>
                              <a href="javascript: void(0);" className="action-icon">
                                <i
                                  role="button"
                                  onClick={() => handleDelete(client)}
                                  onKeyPress={() => handleDelete(client)}
                                  className="mdi mdi-delete"
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Clients;
