/* OPERATIONS */
export const GET_AUDIT = 'GET_AUDIT';
export const GET_AUDIT_SUCCESS = 'GET_AUDIT_SUCCESS';
export const GET_AUDIT_FAILED = 'GET_AUDIT_FAILED';
export const SEARCH_AUDIT = 'SEARCH_AUDIT';
export const SEARCH_AUDIT_SUCCESS = 'SEARCH_AUDIT_SUCCESS';
export const SEARCH_AUDIT_FAILED = 'SEARCH_AUDIT_FAILED';

export const SEARCH_SESSION = 'SEARCH_SESSION';
export const SEARCH_SESSION_SUCCESS = 'SEARCH_SESSION_SUCCESS';
export const SEARCH_SESSION_FAILED = 'SEARCH_SESSION_FAILED';

export const LOAD_TRACES_MAC_LIST = 'LOAD_TRACES_MAC_LIST';
export const LOAD_TRACES_MAC_LIST_SUCCESS = 'LOAD_TRACES_MAC_LIST_SUCCESS';
export const LOAD_TRACES_MAC_LIST_FAILED = 'LOAD_TRACES_MAC_LIST_FAILED';

export const DELETE_MAC_FROM_TRACE = 'DELETE_MAC_FROM_TRACE';
export const DELETE_MAC_FROM_TRACE_SUCCESS = 'DELETE_MAC_FROM_TRACE_SUCCESS';
export const DELETE_MAC_FROM_TRACE_FAILED = 'DELETE_MAC_FROM_TRACE_FAILED';

export const ADD_MAC_TO_TRACE = 'ADD_MAC_TO_TRACE';
export const ADD_MAC_TO_TRACE_SUCCESS = 'ADD_MAC_TO_TRACE_SUCCESS';
export const ADD_MAC_TO_TRACE_FAILED = 'ADD_MAC_TO_TRACE_FAILED';

export const SEARCH_TRACES = 'SEARCH_TRACES';
export const SEARCH_TRACES_SUCCESS = 'SEARCH_TRACES_SUCCESS';
export const SEARCH_TRACES_FAILED = 'SEARCH_TRACES_FAILED';

export const SEARCH_LOGS = 'SEARCH_LOGS';
export const SEARCH_LOGS_SUCCESS = 'SEARCH_LOGS_SUCCESS';
export const SEARCH_LOGS_FAILED = 'SEARCH_LOGS_FAILED';

export const LOAD_NODES_CONTROL = 'LOAD_NODES_CONTROL';
export const LOAD_NODES_CONTROL_SUCCESS = 'LOAD_NODES_CONTROL_SUCCESS';
export const LOAD_NODES_CONTROL_FAILED = 'LOAD_NODES_CONTROL_FAILED';
export const NODES_CONTROL_START = 'NODES_CONTROL_START';
export const NODES_CONTROL_START_FAILED = 'NODES_CONTROL_START_FAILED';
export const NODES_CONTROL_STOP = 'NODES_CONTROL_STOP';
export const NODES_CONTROL_STOP_FAILED = 'NODES_CONTROL_STOP_FAILED';
