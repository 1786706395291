// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Card, CardBody, Collapse, Button, CardHeader } from 'reactstrap';

type PortletProps = {
  className?: string,
  children?: PropTypes.object,
  header: string,
};

type PortletState = {
  collapse?: boolean,
  loading?: boolean,
  hidden?: boolean,
};

/**
 * Portlet
 */
class Portlet extends Component<PortletProps, PortletState> {
  constructor(props: PortletProps) {
    super(props);
    this.toggleContent = this.toggleContent.bind(this);
    this.reloadContent = this.reloadContent.bind(this);
    this.remove = this.remove.bind(this);

    this.state = { collapse: true, loading: false, hidden: false };
  }

  /**
   * Toggle the body
   */
  /* :: toggleContent: () => void */
  toggleContent() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  /**
   * Reload the content
   */
  /* :: reloadContent: () => void */
  reloadContent() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500 + 300 * (Math.random() * 5));
  }

  /**
   * remove the portlet
   */
  /* :: remove: () => void */
  remove() {
    this.setState({ hidden: true });
  }

  render() {
    const children = this.props.children || null;
    const header = this.props.header;

    return !this.state.hidden ? (
      <Card className={classNames(this.props.className)}>
        {this.state.loading && (
          <div className="card-disabled">
            <div className="card-portlets-loader" />
          </div>
        )}
        <CardHeader>
          <Row>
            <Col lg={6} md={6} xs={6}>
              <h5>{header}</h5>
            </Col>
            <Col lg={6} md={6} xs={6}>
              <div className="card-widgets mt-2">
                <Button color="link" className="card-action" onClick={this.toggleContent}>
                  <i
                    className={classNames('mdi', {
                      'mdi-minus': this.state.collapse,
                      'mdi-plus': !this.state.collapse,
                    })}
                  />
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="content-background">
          <Collapse isOpen={this.state.collapse}>{children}</Collapse>
        </CardBody>
      </Card>
    ) : null;
  }
}

export default Portlet;
