/* eslint-disable radix */
/* eslint-disable no-script-url */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow
import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuidv1 from 'uuid/v1';
import classNames from 'classnames';
import { addNasClient, deleteNasClient } from '../../redux/actions';
import UserRoleChecker from '../../components/UserRoleChecker';

const RadiusGroup = props => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.Cluster.clusters);
  const { clusters } = useSelector(state => state.Cluster);
  const { isEditMode } = useSelector(state => state.Cluster);
  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({ virtualserver: 'mcare-nas-8950', acct: 1, auth: 1, enabled: 1, macauth: 1 });
  const [clusterId, setClusterId] = useState({ cluster: data[0].cluster.id });
  const [serverGroup, setServerGroup] = useState(data[0].cluster.serverGroup);

  const toggle = async () => {
    await setModal(!modal);
    if (modal) {
      setInputs({ virtualserver: 'mcare-nas-8950', acct: 1, auth: 1, enabled: 1, macauth: 1 });
    }
  };

  const handleEdit = client => {
    setInputs(client);
    toggle();
  };

  const handleDelete = group => {
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId.cluster);
    const index = serverGroup.indexOf(group);
    if (index > -1) {
      // clients.splice(index, 1);
      cluster.cluster.serverGroup.splice(index, 1);
      setServerGroup(cluster.cluster.serverGroup);
    }
    dispatch(deleteNasClient(clustersClone));
  };

  const handleSearch = e => {
    e.preventDefault();
    const cluster = data.find(cluster => cluster.cluster.id === clusterId.cluster);
    if (cluster) {
      return setServerGroup(cluster.cluster.serverGroup || []);
    }
    return setServerGroup([]);
  };

  const saveServerGroup = clustersClone => {
    dispatch(addNasClient(clustersClone));
    setInputs({});
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const clustersClone = Object.assign({}, clusters);
    const cluster = clustersClone.data.find(cluster => cluster.cluster.id === clusterId.cluster);

    if (inputs.id) {
      const clientsEdited = cluster.cluster.serverGroup.map(group => {
        if (group.id === inputs.id) {
          group = {
            ...inputs,
            acct: parseInt(inputs.acct),
            auth: parseInt(inputs.auth),
            enabled: parseInt(inputs.enabled),
            macauth: parseInt(inputs.macauth),
          };
        }
        return group;
      });
      cluster.cluster.serverGroup = clientsEdited;
    } else if (cluster.cluster.serverGroup) {
      cluster.cluster.serverGroup.push({
        ...inputs,
        id: uuidv1(),
        acct: parseInt(inputs.acct),
        auth: parseInt(inputs.auth),
        enabled: parseInt(inputs.enabled),
        macauth: parseInt(inputs.macauth),
        serverClient: [],
      });
    } else {
      cluster.cluster.serverGroup = [
        {
          ...inputs,
          id: uuidv1(),
          acct: parseInt(inputs.acct),
          auth: parseInt(inputs.auth),
          enabled: parseInt(inputs.enabled),
          macauth: parseInt(inputs.macauth),
          serverClient: [],
        },
      ];
    }
    saveServerGroup(clustersClone);
    return setServerGroup(cluster.cluster.serverGroup);
  };

  const isEmpty = (val) => { return val === null || val === undefined || val === '' };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Server Group</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Form>
                <FormGroup>
                  <Label for="acct">Acct</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="acct"
                    id="acct"
                    value={inputs.acct}>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Input>
                  <Label for="auth">Auth</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="auth"
                    id="auth"
                    value={inputs.auth}>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Input>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    className="mb-2"
                    name="name"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    id="name"
                    value={inputs.name}
                  />
                  <Label for="type">Type</Label>
                  <Input
                    type="text"
                    className="mb-2"
                    name="type"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    id="type"
                    value={inputs.type}
                  />
                  <Label for="enabled">Enabled</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="enabled"
                    id="enabled"
                    value={inputs.enabled}>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Input>
                  <Label for="macauth">Mac Auth</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="macauth"
                    id="macauth"
                    value={inputs.macauth}>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Input>
                  <Label for="virtualserver">Virtual Server</Label>
                  <Input
                    type="select"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    className="form-control mb-2"
                    name="virtualserver"
                    id="virtualserver"
                    value={inputs.virtualserver}>
                    <option selected="selected" value="mcare-nas-8950">
                      mcare-nas-8950
                    </option>
                    <option value="mcare-nas-vex">mcare-nas-vex</option>
                    <option value="mcare-nas-fon">mcare-nas-fon</option>
                    <option value="mcare-nas-meraki">mcare-nas-meraki</option>
                  </Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={
              isEmpty(inputs.acct) ||
              isEmpty(inputs.auth) ||
              isEmpty(inputs.name) ||
              isEmpty(inputs.type) ||
              isEmpty(inputs.enabled) ||
              isEmpty(inputs.macauth) ||
              isEmpty(inputs.virtualserver)
            }
            onClick={e => handleSubmit(e)}
            color="primary">
            Save Server Group
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Radius Server Group</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form onSubmit={e => e.preventDefault()} inline>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="action" className="mr-2" md={1}>
                      Cluster:
                    </Label>
                    <Input
                      type="select"
                      style={{ minWidth: '200px' }}
                      onChange={e => setClusterId({ ...clusterId, [e.target.name]: e.target.value })}
                      className="form-control"
                      name="cluster"
                      id="cluster">
                      {data.map(cluster => (
                        <option value={cluster.cluster.id}>{cluster.cluster.name}</option>
                      ))}
                    </Input>
                    <Button onClick={e => handleSearch(e)} color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col lg={10} sm={10} md={10}>
                    <h4>The list of Radius Server Group</h4>
                  </Col>
                  <UserRoleChecker mustBeAdmin={true}>
                    <Col lg={2} sm={2} md={2}>
                      <Button disabled={!isEditMode} color="primary" onClick={toggle}>
                        Add New Server Group
                      </Button>
                    </Col>
                  </UserRoleChecker>
                </Row>
              </CardTitle>
              <Row>
                <Col lg={12} md={12}>
                  {serverGroup.length === 0 && (
                    <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
                  )}
                  {serverGroup.length > 0 && (
                    <Table striped>
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th>Acct</th>
                          <th>Auth</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Enabled</th>
                          <th>Mac Auth</th>
                          <th>Virtual Server</th>
                          <th className={classNames({ hide: !isEditMode })}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serverGroup.map(group => (
                          <tr style={{ cursor: 'pointer', textAlign: 'center' }}>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              <Badge color={parseInt(group.acct) ? 'success' : 'danger'}>
                                {parseInt(group.acct) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              <Badge color={parseInt(group.auth) ? 'success' : 'danger'}>
                                {parseInt(group.auth) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              {group.name}
                            </td>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              {group.type}
                            </td>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              <Badge color={parseInt(group.enabled) ? 'success' : 'danger'}>
                                {parseInt(group.enabled) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              <Badge color={parseInt(group.macauth) ? 'success' : 'danger'}>
                                {parseInt(group.macauth) ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td onClick={() => props.history.push(`/integrations/${clusterId.cluster}/${group.id}`)}>
                              {group.virtualserver}
                            </td>
                            <td className={classNames({ hide: !isEditMode }, 'table-action')}>
                              <a href="javascript: void(0);" className="action-icon">
                                <i
                                  role="button"
                                  onClick={() => handleEdit(group)}
                                  onKeyPress={() => handleEdit(group)}
                                  className="mdi mdi-pencil"
                                />
                              </a>
                              <a href="javascript: void(0);" className="action-icon">
                                <i
                                  role="button"
                                  onClick={() => handleDelete(group)}
                                  onKeyPress={() => handleDelete(group)}
                                  className="mdi mdi-delete"
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(RadiusGroup);
