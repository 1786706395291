class AuthSingleton {
  constructor() {
    this.idToken = null;
  }

  setIdToken(idToken) {
    this.idToken = idToken;
  }

  getIdToken() {
    return this.idToken;
  }
}

const Auth = new AuthSingleton();

export default Auth;
