// @flow
import {
  SEARCH_LOGS,
  SEARCH_LOGS_SUCCESS,
  SEARCH_LOGS_FAILED
} from './constants';

type LogsAction = { type: string, payload: {} | string | Array<Object> };

export const searchLogs = (params: Object): LogsAction => ({
  type: SEARCH_LOGS,
  params,
});

export const searchLogsSuccess = (logs: Object): LogsAction => ({
  type: SEARCH_LOGS_SUCCESS,
  payload: logs,
});


export const searchLogsFailed = (error: string): LogsAction => ({
  type: SEARCH_LOGS_FAILED,
  payload: error,
});
