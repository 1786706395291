/* eslint-disable react/destructuring-assignment */
// @flow
import { useSelector } from 'react-redux';

export default function UserRoleChecker(props) {
  const { user } = useSelector(state => state.Auth);

  if (props.mustBeAdmin && !user.roles.isMobiAaaAdmin) {
    return null;
  }
  
  return props.children;
}