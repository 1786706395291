/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow
import React, { useState } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import uuidv1 from 'uuid/v1';
import { addCluster, deleteCluster } from '../../redux/actions';
import UserRoleChecker from '../../components/UserRoleChecker';

const Clusters = props => {
  const dispatch = useDispatch();
  const { isEditMode } = useSelector(state => state.Cluster);
  const { data } = useSelector(state => state.Cluster.clusters);
  const { clusters } = useSelector(state => state.Cluster);
  const [inputs, setInputs] = useState({ name: '', radiusHost: '' });
  const [modal, setModal] = useState(false);

  const toggle = async () => {
    await setModal(!modal);
    if (modal) {
      setInputs({});
    }
  };

  const saveCluster = clusters => {
    dispatch(addCluster(clusters));
    setInputs({});
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (inputs.id) {
      const cluster = clusters.data.find(cluster => cluster.cluster.id === inputs.id);
      cluster.cluster.name = inputs.name;
      cluster.cluster.radiusHost = inputs.radiusHost;
    } else {
      clusters.data.push({ cluster: { id: uuidv1(), name: inputs.name, radiusHost: inputs.radiusHost } });
    }
    saveCluster(clusters);
  };

  const handleDelete = cluster => {
    const index = data.indexOf(cluster);
    if (index > -1) {
      data.splice(index, 1);
    }
    dispatch(deleteCluster(clusters));
  };

  const handleEdit = async cluster => {
    await setInputs(cluster);
    console.log(inputs);
    toggle();
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Cluster</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Form>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    name="name"
                    id="name"
                    value={inputs.name}
                  />
                  <Label className="mt-2" for="name">
                    Radius Host
                  </Label>
                  <Input
                    type="text"
                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                    name="radiusHost"
                    id="radiusHost"
                    value={inputs.radiusHost}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button disabled={!inputs.name || !inputs.radiusHost} onClick={e => handleSubmit(e)} color="primary">
            Save Cluster
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Clusters</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col lg={10} sm={10} md={10}>
                    <h4>The list of Cluster</h4>
                  </Col>
                  <UserRoleChecker mustBeAdmin={true}>
                    <Col lg={2} sm={2} md={2}>
                      <Button disabled={!isEditMode} onClick={toggle} color="primary">
                        Add New Cluster
                      </Button>
                    </Col>
                  </UserRoleChecker>
                </Row>
              </CardTitle>
              <Row>
                <Col lg={12} md={12}>
                  <Table striped>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Name</th>
                        <th>Radius Host</th>
                        <th className={classNames({ hide: !isEditMode })}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clusters.data.map(item => (
                        <tr style={{ cursor: 'pointer', textAlign: 'center' }}>
                          <td onClick={() => props.history.push(`/system/clusters/${item.cluster.id}/nodes`)}>
                            {item.cluster.name}
                          </td>
                          <td onClick={() => props.history.push(`/system/clusters/${item.cluster.id}/nodes`)}>
                            {item.cluster.radiusHost}
                          </td>
                          <td className={classNames({ hide: !isEditMode }, 'table-action')}>
                            <button className="action-icon clear-background clear-border">
                              <i
                                role="button"
                                onClick={() => handleEdit(item.cluster)}
                                onKeyPress={() => handleEdit(item.cluster)}
                                className="mdi mdi-pencil"
                              />
                            </button>
                            <button className="action-icon clear-background clear-border">
                              <i
                                role="button"
                                onClick={() => handleDelete(item)}
                                onKeyPress={() => handleDelete(item)}
                                className="mdi mdi-delete"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Clusters;
