/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { getVersions, deployVersion } from '../../redux/actions';
import Spinner from '../../components/Spinner';

const renderLoading = (loadingVersions: Boolean) => {
  if (loadingVersions) {
    return (
      <Col
        lg={{ size: 12, offset: 5 }}
        md={{ size: 12, offset: 5 }}
        sm={{ size: 12, offset: 5 }}
        xs={{ size: 12, offset: 5 }}
        className="mt-5">
        <Spinner className="text-primary" type="grow" size="lg" />
      </Col>
    );
  }
  return null;
};

const stringfyNodes = (nodesMapped, nodeIdArr) => {
  const res = nodeIdArr.reduce((acc, nodeId) => {
        return acc
          .concat(nodesMapped && nodesMapped[nodeId] ? `${nodesMapped[nodeId].name} (${nodesMapped[nodeId].clusterName})` : nodeId)
          .concat(', ');
      }, ''
    );
  
  return res.slice(0, -2);
}

const Versions = () => {
  const dispatch = useDispatch();
  const { versions, loadingVersions, loadingDeploy, error, showModal, nodesVersionMap } = useSelector(state => state.Versions);
  const { data } = useSelector(state => state.Cluster.clusters);
  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({ node: '' });
  const [version, setVersion] = useState({ version: '', id: '' });
  const [visible, setVisible] = useState(true);
  const nodesMapped = useSelector(state => state.Cluster.nodesMapped);

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    dispatch(getVersions());
  }, [dispatch]);

  useEffect(() => {
    setModal(showModal);
  }, [showModal]);

  const handleSubmit = e => {
    e.preventDefault();
    const payload = Object.assign({}, { version: version.id, node: inputs.node });
    dispatch(deployVersion(payload));
  };

  const toggle = () => setModal(!modal);

  const renderVersions = (versions: Array<Object>, loadingVersions: Boolean) => {
    if (versions.length > 0 && !loadingVersions) {
      return (
        <Card>
          <CardBody>
            <CardTitle>
              <h4>The list of Versions</h4>
            </CardTitle>
            <Row>
              <Col lg={12} md={12}>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Version Label</th>
                      <th>Description</th>
                      <th>Author</th>
                      <th>Data Created</th>
                      <th>Deployed To</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versions.map(version => (
                      <tr key={version.id}>
                        <td>{version.version}</td>
                        <td>{version.description}</td>
                        <td>{version.createdBy}</td>
                        <td>{new Date(version.currentDate).toLocaleString()}</td>
                        <td>
                          {stringfyNodes(nodesMapped, version.deployedTo)}
                        </td>
                        <td className="table-action">
                          <div style={{minWidth: '51px', maxWidth: '51px'}}>
                            <a
                              href={`https://mobiaaa-api.wifioi.com.br/mobiaaa/api/versions/${version.id}/download`}
                              className="action-icon">
                              <i
                                role="button"
                                // onClick={() => fileDownload(JSON.stringify(version), `version-${version.version}.json`)}
                                // onKeyPress={() =>
                                //   fileDownload(JSON.stringify(version), `version-${version.version}.json`)
                                // }
                                className="mdi mdi-file-download"
                                style={{ cursor: 'pointer' }}
                              />
                            </a>
                            <a href="javascript: void(0);" className="action-icon">
                              <i
                                role="button"
                                onClick={() => {
                                  setVersion(version);
                                  toggle();
                                }}
                                onKeyPress={() => toggle()}
                                className="mdi mdi-cloud-upload"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
    return null;
  };

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Versions</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {renderLoading(loadingVersions)}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Deploy Version {version.version}</ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <Alert color="danger" isOpen={visible && error} toggle={onDismiss}>
                    {error}
                  </Alert>
                  <Form>
                    {data.map(cluster => (
                      <FormGroup tag="fieldset">
                        <legend>{cluster.cluster.name}</legend>
                        {cluster.cluster.nodes &&
                          cluster.cluster.nodes.length > 0 &&
                          cluster.cluster.nodes.map(node => {
                            const nodeIdWithoutHyphens = node.id ? node.id.replace(/-/g, '') : null;
                            const nodeVersion = nodeIdWithoutHyphens && nodesVersionMap ? nodesVersionMap[nodeIdWithoutHyphens] : null;

                            return (
                              <FormGroup check>
                                <Label className="ml-2 mt-2" check>
                                  <Input
                                    type="radio"
                                    onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                                    name="node"
                                    value={node.id}
                                  />
                                  {node.name} {nodeVersion ? `(Current Version: ${nodeVersion.version})` : ''}
                                </Label>
                              </FormGroup>
                            )
                          })}
                      </FormGroup>
                    ))}
                  </Form>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button disabled={!inputs.node || loadingDeploy} color="primary" onClick={e => handleSubmit(e)}>
                {loadingDeploy ? 'Loading...' : 'Deploy'}
              </Button>
            </ModalFooter>
          </Modal>
          {renderVersions(versions, loadingVersions)}
        </Col>
      </Row>
    </>
  );
};

export default Versions;
