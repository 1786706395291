// @flow
import {
  GET_CLUSTER,
  ENABLE_EDIT_MODE,
  EXIT_EDIT_MODE,
  GET_CLUSTER_SUCCESS,
  GET_CLUSTER_FAILED,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILED,
  UPDATE_CLUSTER,
  UPDATE_DASHBOARD,
  SAVE_VERSION,
  SAVE_VERSION_SUCCESS,
  SAVE_VERSION_FAILED,
  ADD_PROPERTY,
  DELETE_PROPERTY,
  ADD_CLUSTER,
  DELETE_CLUSTER,
  ADD_NAS_CLIENT,
  DELETE_NAS_CLIENT,
} from './constants';

import Cluster from './models';

type ClusterAction = { type: string, payload: {} | string | Array<typeof Cluster> | Boolean };

export const enableEditMode = (payload: Object): ClusterAction => ({
  type: ENABLE_EDIT_MODE,
  payload
});

export const getCluster = (): ClusterAction => ({
  type: GET_CLUSTER,
  payload: {},
});

export const getClusterSuccess = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: GET_CLUSTER_SUCCESS,
  payload: clusters,
});

export const getClusterFailed = (error: string): ClusterAction => ({
  type: GET_CLUSTER_FAILED,
  payload: error,
});

export const getDashboard = (): ClusterAction => ({
  type: GET_DASHBOARD,
  payload: {},
});

export const getDashboardSuccess = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: clusters,
});

export const getDashboardFailed = (error: string): ClusterAction => ({
  type: GET_DASHBOARD_FAILED,
  payload: error,
});

export const updateCluster = (): ClusterAction => ({
  type: UPDATE_CLUSTER,
  payload: {},
});

export const updateDashboard = (): ClusterAction => ({
  type: UPDATE_DASHBOARD,
  payload: {},
});

export const exitEditMode = (): ClusterAction => ({
  type: EXIT_EDIT_MODE,
  payload: {},
});

export const saveVersion = (clusters: Array<typeof Cluster>) => ({
  type: SAVE_VERSION,
  payload: clusters,
});

export const saveVersionSuccess = () => ({
  type: SAVE_VERSION_SUCCESS,
  payload: { isEditMode: false },
});

export const saveVersionFailed = (error: string) => ({
  type: SAVE_VERSION_FAILED,
  payload: error,
});

export const addProperty = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: ADD_PROPERTY,
  payload: clusters,
});

export const deleteProperty = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: DELETE_PROPERTY,
  payload: clusters,
});

export const addNasClient = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: ADD_NAS_CLIENT,
  payload: clusters,
});

export const deleteNasClient = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: DELETE_NAS_CLIENT,
  payload: clusters,
});

export const addCluster = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: ADD_CLUSTER,
  payload: clusters,
});

export const deleteCluster = (clusters: Array<typeof Cluster>): ClusterAction => ({
  type: DELETE_CLUSTER,
  payload: clusters,
});
