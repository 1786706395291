/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText } from 'reactstrap';
import { searchTraces, loadTracesMacList } from '../../redux/actions';
import Spinner from '../../components/Spinner';
import TracesModal from './TracesModal';
import TraceItem from './TraceItem';
import ErrorAlert from './ErrorAlert';

const renderTraces = (traces: Array<Object>, loadingTraces: Boolean) => {
  if (loadingTraces) {
    return null;
  }

  if (traces && traces.length >= 1) {
    return (
        <Col xl={12}>
          <Card>
            <CardBody>
              {
                traces.map((trace, index) => <TraceItem key={index} data={trace} />)
              }
            </CardBody>
          </Card>
        </Col>
    )
  }

  if (!traces || traces.length < 1) {
    return (
      <Col xl={12}>
        <Card>
          <CardBody>
            <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return null;
};

const renderLoading = (loadingTraces: Boolean) => {
  if (loadingTraces) {
    return (
      <Col
        lg={{ size: 12, offset: 5 }}
        md={{ size: 12, offset: 5 }}
        sm={{ size: 12, offset: 5 }}
        xs={{ size: 12, offset: 5 }}
        className="mt-5">
        <Spinner className="text-primary" type="grow" size="lg" />
      </Col>
    );
  }
  return null;
};

const Traces = () => {
  const dispatch = useDispatch();
  const { traces, loadingTraces, macs, loadingTracesMacList, error } = useSelector(state => state.OperationsTraces);
  const [inputs, setInputs] = useState({ searchMac: null });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    dispatch(loadTracesMacList());
  }, [dispatch]);

  const handleSearch = e => {
    e.preventDefault();
    if (handleValidation()) {
      const params = {...inputs};
      dispatch(searchTraces(params));
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    const validationErrors = {};

    if (!inputs.searchMac) {
      formIsValid = false;
      validationErrors.searchMac = 'Cannot be empty';
    }

    setValidationErrors(validationErrors);
    return formIsValid;
  };

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Traces</h4>
          </div>
        </Col>
      </Row>
      <ErrorAlert error={error}/>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <div className="card-widgets">
                <TracesModal />
              </div>
              <Form inline onSubmit={e => handleSearch(e)}>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="searchMac" className="mr-2" md={1}>
                      Query:
                    </Label>
                    <Input 
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      type="select" 
                      className="form-control" 
                      name="searchMac" 
                      id="searchMac"
                      style={{ width: '200px' }}>
                        {
                          loadingTracesMacList && <option>Carregando...</option>
                        }
                        {
                          !loadingTracesMacList && (
                            <>
                              <option>Selecione um MAC</option>
                              {
                                macs && macs.length > 0 && macs.map(mac => <option key={mac.id} value={mac.macaddress}>{mac.macaddress}</option>)
                              }
                            </>)
                        }  
                    </Input>
                    <FormText color="danger" className="mb-2">
                      {validationErrors.searchMac}
                    </FormText>
                    <Button color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {renderTraces(traces, loadingTraces)}
        {renderLoading(loadingTraces)}
      </Row>
    </>
  );
};

export default Traces;
