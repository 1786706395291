import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import Routes from './routes/Routes';

import { AUTH0_CLIENT_ID, AUTH0_FORCE_CONNECTION } from './config';

// Themes
import './assets/scss/DefaultTheme.scss';

// For Dark import DarkTheme.scss
// import './assets/scss/DarkTheme.scss';

// For green - import GreenTheme.scss
// import './assets/scss/GreenTheme.scss';

/**
 * Main app component
 */

const App = () => {
  // TODO: o client id e domain deveriam vir do client.json

  return (
    <Auth0Provider
      domain="akross-tenant.us.auth0.com"
      clientId={AUTH0_CLIENT_ID}
      connection={AUTH0_FORCE_CONNECTION}
      redirectUri={window.location.origin}>
      <Routes />
    </Auth0Provider>
  );
};

export default App;
