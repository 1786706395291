import React from 'react';
import Chart from 'react-apexcharts';

const StackedAreaChart = React.memo(({ title, subtitle, height, series, xAxisLabelFormatter }) => {
  // const chartOptions = {
  //   chart: { type: 'area', sparkline: { enabled: false }, stacked: false },
  //   stroke: { width: 2, curve: 'straight' },
  //   // fill: { opacity: 0.2 },
  //   // xaxis: { crosshairs: { width: 1 } },
  //   // yaxis: { min: 0 },
  //   colors: ['#0acf97'],
  //   title: { text: `${title}`, offsetX: 20, style: { fontSize: '24px' } },
  //   subtitle: { text: `${subtitle}`, offsetX: 20, style: { fontSize: '14px' } },
  // };

  const chartOptions = {
    chart: {
      type: 'area',
    },
    xaxis: {
      type: 'category',
      categories: Array.isArray(series) ? series[0].xaxis : series.xaxis,
      labels: {
        formatter: xAxisLabelFormatter
      }
    },
    colors: ['#727cf5', '#0acf97', '#e3eaef', '#eb3636', '#ecf56c', '#fca24e', '#7fe3a9', '#1531e8'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: '#f1f3fa',
    },
    title: { text: `${title || ''}`, offsetX: 20, style: { fontSize: `${subtitle ? '24px' : '20px'}` } },
    subtitle: { text: `${subtitle || ''}`, offsetX: 20, style: { fontSize: '14px' } },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
        },
      },
    ],
  };

  return (
    <Chart
      series={Array.isArray(series) ? series : [series]}
      options={chartOptions}
      type="area"
      height={height}
      className="apex-charts"
    />
  );
});

export default StackedAreaChart;
