// @flow
import {
  SEARCH_SESSION,
  SEARCH_SESSION_SUCCESS,
  SEARCH_SESSION_FAILED
} from './constants';

type SessionsAction = { type: string, payload: {} | string | Array<Object> };

export const searchSession = (params: Object): SessionsAction => ({
  type: SEARCH_SESSION,
  payload: {},
  params,
});

export const searchSessionSuccess = (session: Object): SessionsAction => ({
  type: SEARCH_SESSION_SUCCESS,
  payload: session,
});


export const searchSessionFailed = (error: string): SessionsAction => ({
  type: SEARCH_SESSION_FAILED,
  payload: error,
});
