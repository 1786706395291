/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { searchSession } from '../../redux/actions';
import Spinner from '../../components/Spinner';
import ErrorAlert from './ErrorAlert';

const renderSession = (session: Object, loadingSession: Boolean) => {
  if (!loadingSession && session) {
    return (
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <p className="text-muted font-14 mb-4">
                Your search returned the following attributes.
            </p>
            <div className="table-responsive-sm">
              <table className="table table-striped table-centered mb-0">
                  <thead>
                      <tr>
                          <th width="40%">Attribute</th>
                          <th>Value</th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(session).map(k => {
                        return (
                          <tr>
                            <td>{k}</td>
                            <td>
                              <span style={{wordBreak: 'break-word'}}>{session[k]}</span>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (!session && !loadingSession) {
    return (
      <Col>
        <Card>
          <CardBody>
            <p className="text-muted text-center font-18 mb-4">No results found for your search.</p>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return null;
};

const renderLoading = (loadingSession: Boolean) => {
  if (loadingSession) {
    return (
      <Col
        lg={{ size: 12, offset: 5 }}
        md={{ size: 12, offset: 5 }}
        sm={{ size: 12, offset: 5 }}
        xs={{ size: 12, offset: 5 }}
        className="mt-5">
        <Spinner className="text-primary" type="grow" size="lg" />
      </Col>
    );
  }
  return null;
};

const getNodesFromClusters = () => {
  // get nodes list
  const clusters = useSelector(state => state.Cluster.clusters).data;
  const nodes = [];
  
  clusters.forEach(it => {
    const cluster = it.cluster;
    if (cluster.nodes && Array.isArray(cluster.nodes)) {
      cluster.nodes.forEach(node => {
        node.clusterName = cluster.name;
        nodes.push(node)
      });
    }
  });
  
  return nodes;
}

const Session = () => {
  const dispatch = useDispatch();
  const { session, loadingSession, error } = useSelector(state => state.OperationsSessions);
  const [inputs, setInputs] = useState({ nodeId: null, sessionId: null });
  const [validationErrors, setValidationErrors] = useState({});
  const [searched, setSearched] = useState(false);
  const nodes = getNodesFromClusters();

  const handleSubmit = e => {
    e.preventDefault();
    if (handleValidation()) {
      setSearched(true);
      const params = {...inputs};
      dispatch(searchSession(params));
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    const validationErrors = {};

    if (!inputs.nodeId) {
      formIsValid = false;
      validationErrors.nodeId = 'Cannot be empty';
    }

    setValidationErrors(validationErrors);
    return formIsValid;
  };

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Sessions</h4>
          </div>
        </Col>
      </Row>
      <ErrorAlert error={error}/>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form inline onSubmit={e => handleSubmit(e)}>
              <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="nodeId" className="mr-2" md={1}>
                      Node:
                    </Label>
                    <Input 
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      type="select" 
                      className="form-control" 
                      name="nodeId" 
                      id="node">
                         <option>Select the Node</option>
                        {nodes.map(node => (
                          <option value={node.id}>{node.clusterName} - {node.name}</option>
                        ))}
                    </Input>
                    <FormText color="danger" className="mb-2">
                      {validationErrors.nodeId}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                  <FormGroup row>
                    <Label for="sessionId" className="mr-2" md={1}>
                      Session:
                    </Label>
                    <Input
                      type="text"
                      onChange={e => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                      className="form-control"
                      name="sessionId"
                      id="sessionId"
                      style={{ width: '340px' }}
                    />
                    <Button color="primary" className="ml-2">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {searched && renderSession(session, loadingSession)}
        {renderLoading(loadingSession)}
      </Row>
    </>
  );
};

export default Session;
